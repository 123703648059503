import React, { useStste, useEffect, useState } from "react";
import { Header } from "../../Components/Common/index";
import DeliveryCard from "./DeliveryCard";
import { avatar } from "../../resources/images/index";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { defaultDateFormat } from "../../utils/helpers";
import { getPackages } from "../../services/packageServices";
import { toast } from "react-toastify";

const DeliveryList = (props) => {
  const [data, setData] = useState([]);
  const [filterdata, setFilterData] = useState([]);

  useEffect(() => {
    packagelist();
    // toast.dismiss();
  }, []);

  const history = useHistory();

  const deliverydetail = (itemid) => history.push(`/delivery-detail/${itemid}`);

  const packagelist = () =>
    getPackages().then((response) => {
      //console.log(response)
      if(response.data)
        response.data.sort((a,b) => new Date(b.travel?.dateOfDelivery).getTime() - new Date(a.travel?.dateOfDelivery).getTime())

      setData(response.data);
      setFilterData(response.data);
    });

  const getFutureTravels = (filter) => {
    let result = [];
    result = data.filter((data) => {
      if (filter === "future") {
        return new Date(data.travel?.dateOfDelivery) >= Date.now();
      } else if (filter === "past") {
        return new Date(data.travel?.dateOfDelivery) < Date.now();
      } else {
        return data;
      }
    });
    setFilterData(result);
  };

  return (
    <div className="mydeliveriesWrapper">
      <div className="deliveriesInner">
        <div className="deliveryCardWrapper">
          <div className="cardLeft"></div>

          <div className="filterBtnWrapper">
            <button name="groupOptions" onClick={() => getFutureTravels("all")}>
              All
            </button>
            <button name="groupOptions" onClick={() => getFutureTravels("future")}>
              Future
            </button>
            <button name="groupOptions" onClick={() => getFutureTravels("past")}>
              Past
            </button>
          </div>
        </div>

        {filterdata &&
          filterdata.map((item, index) => {
            return (
              <DeliveryCard
                key={index}
                id={item?._id}
                img={avatar}
                name={item?.name}
                requesttext="Detail"
                tracktext="Track"
                date={`${defaultDateFormat(item?.travel?.dateOfDelivery)}`}
                weight={item.travel?.weight}
                charges={item.travel?.charges}
                placeofdelivery={item?.travel?.to}
                placeofpickup={item?.travel?.from}
                submitrequest={deliverydetail}
                // imgURL={deliverydetail}
                // nameURL={deliverydetail}
                // submittrack={deliverydetail}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStatetoProps = (userdetail) => {
  const { userDetail } = userdetail;
  return { userDetail };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(DeliveryList);
