import React from 'react';
import {howworks} from '../../resources/images/index'

function HowItWorks(props) {
    return (
        <div className="howItWorks"  id="howitwork">
           <h1>How it works</h1> 
           <p>Greenshipex is a user-friendly app for sharing spare luggage and trunk space, making shipping faster while reducing carbon footprints.</p>
            <div className="howWorksBody">
                <div className="howWorksLeft">
                    <div className="howWorksSteps">
                        <span className="stepNumer"> 1</span>
                        <span className="stepText">
                            <h1>Register on Greenshipex</h1>
                            You can register on Greepshipex.com with a valid email and phone number. Activate your account via an email that sent to your inbox with an activation link!</span>
                    </div>
                    <div className="howWorksSteps">
                        <span className="stepNumer"> 2</span>
                        <span className="stepText">
                        <h1>Add a Travel</h1>
                        On Greenshipex's homepage, easily create a travel plan by providing departure location, date, destination, available space (in Kg), and desired charges for shipping.</span>
                    </div>
                    <div className="howWorksSteps">
                        <span className="stepNumer"> 3</span>
                        <span className="stepText">
                        <h1>Accept Requests</h1>
                        Once the requests are received, you may choose to either accept or reject them. If you decide to accept a request, you can then proceed with communicating directly with the requester.</span>
                    </div>
                    <div className="howWorksSteps">
                        <span className="stepNumer"> 4</span>
                        <span className="stepText">
                        <h1>Travel and Earn</h1>
                        Travel, deliver accepted shipments, and earn income.</span>
                    </div>
                </div>
                <div className="howWorksRight">
                    <img src={howworks} alt="" />
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;