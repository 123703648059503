import React, { useState } from "react";
import { Modal, FormFields } from "../index";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { RegisterUser } from "../../../services/userServices";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { setUserDetails } from "../../../redux/actions/userActions";
import { setLoginState, setPopupState } from "../../../redux/actions/appActions";
import MuiPhoneNumber from "material-ui-phone-number";
import FormDropdown from "../FormFields/FormDropdown";

function ModalRegister( props) {
  const [serverValidationErrors, setServerValidationErrors] = useState({});

  const handleCLose = () => props.setPopupState({registerPopup:false})

  const { handleSubmit, handleChange, setFieldValue, values, touched, errors, handleBlur } = useFormik({
    initialValues: {
      firstname: "",
      name: "", 
      useremail: "",
      username: "user" + (Math.floor(Math.random() * 9999999999) + 100000),
      sex: "",
      password: "",
      phonenumber: "+",
    },
    validationSchema: yup.object({
      firstname: yup.string().required("First name should not be empty."),
      name: yup.string().required("Last name should not be empty."),
      /*username: yup.string().required("Should not be empty."),*/
      sex: yup.string().required("Gender should not be empty."),
      phonenumber: yup.string().matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid').required("Phone number should not be empty."),
      useremail: yup.string().email("Invalid email format").required("Email should not be empty."),
      password: yup
        .string()
        .required("Password should not be empty.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    }),
    onSubmit: async (values) => {
      const result = await registerUser(values);
      if (result) handleCLose();
    },
  });

  async function registerUser(data) {
    if(Object.values(serverValidationErrors).find(itm => itm))
      return;
    const result = await RegisterUser(data)
      .then((response) => {
        if (response.data && !response.data?.error) {
          props.setUserDetails(response.data)
          toast.success(() => (
            <>
              <b>Verification link has been sent on your email address</b>
              <br />
              Please verify your email
            </>
          ));
          return response.data;
        } else {
          if (response.data?.error) {
            const { email,phonenumber } = response.data.error;
            setServerValidationErrors(response.data.error)
            email &&
              toast.error(
                "Email address is already registered, please try different email address"
              );
            phonenumber &&
              toast.error(
                "Phone number is already registered, please try different phone number"
              );
          } else toast.error("An error occured!");
        }
        return null;
      })
      .catch((err) => {
        return null;
      });
    return result;
  }

  return (
    <div>
      <Modal
        closeModal={handleCLose}
        submitModal={handleSubmit}
        buttontext="Register"
        title="Register"
      >
        <form onSubmit={handleSubmit} autoComplete="none">
          <FormFields
            type="text"
            id="firstname"
            name="firstname"
            placeholder="First name"
            value={values.firstname}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.firstname && errors.firstname ? (
            <span className="errorMessage">{errors.firstname}</span>
          ) : null}
          <FormFields
            type="text"
            id="name"
            name="name"
            placeholder="Last name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.name && errors.name ? <span className="errorMessage">{errors.name}</span> : null}

          <FormDropdown 
            value={values.sex} 
            name="sex"
            onChange={handleChange}
            onBlur={handleBlur}
            options={[{value:"", text: "Select Gender"},
            {value:"female", text: "Female"},
            {value:"male", text: "Male"},
            {value:"other", text: "Other"},
            {value:"dontWantToSpecify", text: "Don't want to specify"},]}
          />
          {touched.sex && errors.sex ? <span className="errorMessage">{errors.sex}</span> : null}
          {/*<FormFields*/}
          {/*  type="text"*/}
          {/*  id="username"*/}
          {/*  name="username"*/}
          {/*  placeholder="User Name"*/}
          {/*  value={values.username}*/}
          {/*  onChange={(e)=>{*/}
          {/*    handleChange(e); */}
          {/*    setServerValidationErrors({...serverValidationErrors, username:null})*/}
          {/*  }}*/}
          {/*  onBlur={handleBlur}*/}
          {/*/>*/}
          {/*{(touched.username && errors.username || serverValidationErrors.username) ? (*/}
          {/*  <span className="errorMessage">{touched.username && errors.username || serverValidationErrors.username}</span>*/}
          {/*) : null}*/}
          <FormFields
            type="email"
            name="useremail"
            placeholder="Email"
            value={values.useremail}
            onChange={(e)=>{
              handleChange(e); 
              setServerValidationErrors({...serverValidationErrors, email:null})
            }}
            onBlur={handleBlur}
          />
          {(touched.useremail && errors.useremail || serverValidationErrors.email) ? (
            <span className="errorMessage">{touched.useremail && errors.useremail || serverValidationErrors.email}</span>
          ) : null}
 
          <MuiPhoneNumber 
              defaultCountry={"de"}
              
              name="phonenumber" 
              className="popupPhoneNumberInput"
              value={values.phonenumber}
              style={{display: "flex", margin: "10px 0px"}}
              onChange={(value)=>{
                console.log({value})
                setFieldValue("phonenumber", value ? value.replace(/ |\-/g, "") : value)
                setServerValidationErrors({...serverValidationErrors, phonenumber:null})
              }}
            />

          {(touched.phonenumber && errors.phonenumber || serverValidationErrors.phonenumber) ? (
            <span className="errorMessage">{touched.phonenumber && errors.phonenumber || serverValidationErrors.phonenumber}</span>
          ) : null}
          <FormFields
            type="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.password && errors.password ? (
            <span className="errorMessage">{errors.password} </span>
          ) : null}
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch), 
  setPopupState: bindActionCreators(setPopupState, dispatch),
  setLoginState: bindActionCreators(setLoginState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalRegister);
