import React from 'react';
import {FormFields, Textarea, AppBtn} from '../../Components/Common/index';


function ContactUs(props) {
    return (
        <div className="contactusWrapper">
           <h1>Contact us</h1> 
           <div className="contactusInner"> 
           <form>
           <div className="fieldWrapper">
               <FormFields placeholder="First name" />
               <FormFields placeholder="Last name" />
           </div>
           <div className="fieldWrapper">
               <FormFields placeholder="Email" />              
           </div>

           <div className="fieldWrapper textareaWrapper">
               <Textarea placeholder="Message"></Textarea>              
           </div>
           <div className="fieldWrapper">
               <AppBtn text="Submit" />            
           </div>
           </form>
          </div>
       </div>
    );
}

export default ContactUs;