import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

const FormFields = (props) => {
  const {
    value,
    placeholder,
    type,
    children,
    name,
    onChange,
    onSelect,
    onFocus,
    onBlur,
    min,
    defaultValue,
  } = props;

  return (
    <div className="formFieldsWrapper">
      {type === "location" ? (
        <PlacesAutocomplete value={value} onChange={onChange} onSelect={onSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <>
              <input
                {...getInputProps({
                  placeholder: placeholder,
                })}
              />
              {suggestions.length > 0 || loading ? (
                <div className="autocomplete-dropdown-container">
                  {loading && <div className="suggestion-item">Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>
          )}
        </PlacesAutocomplete>
      ) : (
        <input
          value={value}
          placeholder={placeholder}
          type={type}
          name={name}
          onChange={onChange}
          autoComplete="new-password"
          onFocus={onFocus}
          onBlur={onBlur}
          min={min}
          defaultValue={defaultValue}
        />
      )}
      <span className="errorMessage">{children}</span>
    </div>
  );
};

export default FormFields;
