export const actionTypes = {
  SET_LOGIN_STATE : "SET_LOGIN_STATE",
  SET_POPUP_STATE: "SET_POPUP_STATE"
}

export const setPopupState = popupState => ({
  type: actionTypes.SET_POPUP_STATE,
  payload: popupState
})

export const setLoginState = (loginState) => ({
  type: actionTypes.SET_LOGIN_STATE,
  payload: loginState
})
