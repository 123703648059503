import { PackageAPIs } from "../api/apiEndPoints";
import { request } from "../api/axiosDefault";

export const getPackages = () => request({ method: "get", url: PackageAPIs.GET_PACKAGES });

export const getPackage = (params) =>
  request({ method: "get", url: PackageAPIs.GET_PACKAGE, params });

export const postPackage = async (data) =>
  request({ method: "post", url: PackageAPIs.POST_PCAKAGE, data });

export const acceptPackage = async (data) =>
  request({ method: "post", url: PackageAPIs.ACCEPT_PCAKAGE, data });

export const declinePackage = async (data) =>
  request({ method: "post", url: PackageAPIs.DECLINE_PCAKAGE, data });
