import moment from "moment";

export const defaultDateFormat = (date) => moment(date).format("DD MMM YYYY");
export const minDateFormat = (date) => moment(date).format("YYYY-MM-DD");
export const chatHeaderDateFormate = (date) => moment(date).format("ddd, DD MMM, h:mm"); // Thu, 10 Mar, 12:00

export const getFormatedChatDateTime = (date, handleMintsAsWell = false) => {
  if (handleMintsAsWell && moment(date).isSame(moment(new Date()), "hour")) {
    return `${moment(new Date()).diff(new Date(date), "minutes")} min ago`;
  }
  if (moment(date).isSame(moment(new Date()), "day")) {
    return moment(date).format("h:mm");
  }
  return moment(date).format("DD MMM YYYY h:mm:ss");
};
