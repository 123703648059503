import React, { useState, useMemo, useEffect } from "react";
import "./chat.css";
import { useHistory, useParams } from "react-router";
import { arrowIcon, sendMessageIcon, infoIcon } from "../../resources/images/index";
import { io } from "socket.io-client";
import { getMessages } from "../../services/messageServices";
import { chatHeaderDateFormate, getFormatedChatDateTime } from "../../utils/helpers";
import Avatar from "../../Components/Common/avatar";
import { getChat } from "../../services/chatServices";
import { connect } from "react-redux";

function Chat(props) {
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({});
  const [messages, setMessages] = useState([]);
  const [travelInfo, setTravelInfo] = useState();
  const { id } = useParams();
  const currentUserId = props.user?._id;
  const [currentUser, setCurrentUser] = useState(props.user);
  const history = useHistory();

  useEffect(()=>{
    setCurrentUser(props.user)
  }, [props.user])

  const socket = useMemo(() => {
    const skt = io({
      autoConnect: true,
      query: {
        sender: currentUserId,
        chatId: id,
      },
    });
    skt.on("disconnect", () => socket.connect());

    skt.on("send", (msg) => {
      msg.message && setMessages((prev) => [...prev, msg]);
    });

    skt.connect();
    return skt;
  }, [id, currentUserId]);

  const sendMessage = (e) => {
    e.preventDefault();
    const msg = { userId: currentUserId, message };
    socket.emit("send", msg, (response) => {
      console.log(response);
    });
    setMessage("");
    msg.createdAt = new Date().toISOString();
    setMessages((prev) => [...prev, msg]);
  };


  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
  if (e.key === 'Enter') {
    sendMessage(e);
  }
};

  useEffect(() => {
    getMessages(id)
      .then((res) => {
        setMessages(res.data?.messages || []);
        setUser(res.data?.users.find((item) => item._id !== currentUserId));
      })
      .catch((err) => console.log(err));
    getChat(id)
      .then((res) => {
        setTravelInfo(res.data[0].travel);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    window.document.querySelector("#chatBody > div:last-of-type")?.scrollIntoView();
  }, [messages]);

  // const conditionalRender = (condition, element1, element2) => {
  //   return !condition ? element1 : element2;
  // };

  return (
    <div className="inboxParentWrapper">
      <div className="inboxWrapper">
        <div className="chatTopHeader" onClick={() => history.push(`/travel-detail/${travelInfo._id}`)}>
          <div className="alignedFlexRow">
            <img src={infoIcon} className="info" />
            <div className="chatInfo">
              <div className="travelDirection">
                <div className="travelFrom">{travelInfo?.from}</div>
                <img src={arrowIcon} className="direction" />
                <div className="travelTo">{travelInfo?.to}</div>
              </div>
              <div className="headerChatTime">
                {chatHeaderDateFormate(travelInfo?.dateOfPickup)}
              </div>
            </div>
          </div>
          <div style={{ width: "6px", height: "6px" }} className="arrow right"></div>
        </div>
        <div className="chatBody" id="chatBody">
          {/* new chat start date */}
          {/* <div className="newChatDate">Today, 10 Mar 2022</div> */}
          {messages.map((item, index) => {
            // const isIn30MintRangeWithPreviouse =
            //   index + 1 < messages.length &&
            //   moment(item.createdAt).diff(moment(messages[index + 1].createdAt), "minutes") < 30;

            return item.userId == currentUserId ? (
              <div className="sent">
                <div className="messageCard ">
                  <div className="messageWrapper">
                    {/* {conditionalRender(
                      index > 0 &&
                        messages[index - 1].userId === currentUserId &&
                        !isIn30MintRangeWithPreviouse, */}
                    <div className="sender">Me</div>
                    {/* )} */}
                    <div className="messageBody">
                      <div>{item.message}</div>
                    </div>
                    {/* {conditionalRender(
                      index < messages.length - 1 &&
                        messages[index + 1].userId === currentUserId &&
                        !isIn30MintRangeWithPreviouse, */}
                    <div className="messageTime">{getFormatedChatDateTime(item.createdAt)}</div>
                    {/* )} */}
                  </div>
                  {/* {conditionalRender(
                    index < messages.length - 1 &&
                      messages[index + 1].userId === currentUserId &&
                      !isIn30MintRangeWithPreviouse, */}
                  <div
                    style={{
                      height: "70px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginTop: "10px",
                      marginLeft: "10px",
                      width: "70px",
                      display: "inline-block",
                    }}
                  >
                    <Avatar
                      customStyle={{
                        fontSize: "34px",
                      }}
                      imageLink={
                        currentUser?.profilePicture ? `/${currentUser?.profilePicture}` : null
                      }
                      shortName={currentUser?.name?.[0] + currentUser?.firstname?.[0]}
                    />
                  </div>
                  {/* ,<div className="avatar" style={{ height: "auto" }}></div> */}
                  {/* )} */}
                </div>
              </div>
            ) : (
              <div className="recieved">
                <div className="messageCard ">
                  <div className="messageWrapper">
                    {/* {conditionalRender(
                      index > 0 &&
                        messages[index - 1].userId !== currentUserId &&
                        !isIn30MintRangeWithPreviouse, */}
                    <div className="sender">{user?.firstname}</div>
                    {/* )} */}
                    <div className="messageBody">
                      <div>{item.message}</div>
                    </div>
                    {/* {conditionalRender(
                      index < messages.length - 1 &&
                        messages[index + 1].userId !== currentUserId &&
                        !isIn30MintRangeWithPreviouse, */}
                    <div className="messageTime">{getFormatedChatDateTime(item.createdAt)}</div>
                    {/* )} */}
                  </div>
                  {/* {conditionalRender(
                    index < messages.length - 1 &&
                      messages[index + 1].userId !== currentUserId &&
                      !isIn30MintRangeWithPreviouse, */}
                  <div
                    style={{
                      height: "70px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginTop: "10px",
                      marginRight: "10px",
                      width: "70px",
                      display: "inline-block",
                    }}
                  >
                    <Avatar
                      customStyle={{
                        fontSize: "34px",
                      }}
                      imageLink={user?.profilePicture ? `/${user?.profilePicture}` : null}
                      shortName={user?.name?.[0] + user?.firstname?.[0]}
                    />
                  </div>
                  {/* ,<div className="avatar" style={{ height: "auto" }}></div> */}
                  {/* )} */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="chatInput-footer">
          <input
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder="Write message here"
            type="text"
            onKeyPress={handleKeypress}
          />
          <div style={{ width: "0px", height: "0px", overflow: "visible" }} onClick={sendMessage}>
            <img src={sendMessageIcon} className="sendMessage" />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {...state.userdetail};
};

export default connect(mapStateToProps)(Chat);