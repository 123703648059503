import React, { useEffect } from "react";
import { toast } from "react-toastify";
import AboutUs from "../Home/AboutUs";

const About = () => {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  return (
    <div className="loginWrapper">
      <AboutUs />
    </div>
  );
};

export default About;
