import React, { useState, useEffect, useMemo } from "react";
import AccountInfo from "./AccountInfo";
import Profile from "./Profile";
import PersonalInfoModal from "../../Components/Common/Modal/PersonalInfoModal";
import { GetAccountDetails } from "../../services/userServices";
import { bindActionCreators } from "redux";
import { setUserDetails } from "../../redux/actions/userActions";
import { connect } from "react-redux";

function Account(props) {
  const [profile, setProfile] = useState(true);
  const [account, setAccount] = useState(false);
  const [isPersonalData, setIsPersonalData] = useState(false);
  const profiledata = useMemo( () => props.user, [props.user]);
  

  const showProfile = () => {
    setAccount(false);
    setProfile(true);
  };

  const showAccount = () => {
    setAccount(true);
    setProfile(false);
  };

  useEffect(() => {
    getdata();
    // toast.dismiss();
  }, []);

  const getdata = () =>
    GetAccountDetails().then((response) => {
      props.setUserDetails(response.data)
    });

  return (
    <div className="mydeliveriesWrapper">
      <div className="contsctDetailWrapper">
        <div className="deliveriesInner">
          <div className="account-Tabs">
            <span onClick={showProfile} className={`tab-nav ${profile ? "active" : ""}`}>
              Profile
            </span>
            <span onClick={showAccount} className={`tab-nav ${account ? "active" : ""}`}>
              Account
            </span>
          </div>
          <div className="tabs-Body">
            {profile && (
              <>
                <Profile
                  name={profiledata?.name}
                  subheading={profiledata?.firstname}
                  editprofile="Edit profile data"
                  editprefrences="Edit preferences"
                  phone={profiledata?.phonenumber}
                  email={profiledata?.useremail}
                  isEmailVerified={profiledata?.isEmailVerified}
                  isContactNoVerified={profiledata?.isContactNoVerified}
                  picture={profiledata?.profilePicture ? `/${profiledata.profilePicture}` : null}
                  editPersonalInfo={() => setIsPersonalData(true)}
                  triggerGetFreshData={getdata}
                />
              </>
            )}

            {account && (
              <>
                <AccountInfo
                  ratingrec="Rating Received"
                  ratinggiven="Rating given"
                  notification="Notofication, Email &amp; SMS"
                  changepassword="Change password"
                  payouts="Payouts"
                  paymentmathonds="Payment methods"
                  privacy="Privacy"
                />
              </>
            )}

            {isPersonalData && (
              <PersonalInfoModal
                handleClose={() => setIsPersonalData(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {...state.userdetail};
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
