import React, { useState, useCallback, useContext } from "react";
import { Header, AppBtn, FormFields, Modal, ModalLogin } from ".././../Components/Common/index";
import { useHistory } from "react-router-dom";
import { homescreen, driver, smallImage, sender } from "../../resources/images/index";

import { connect } from "react-redux";
import Cards from "./Cards";
import HowItWorks from "./HowItWorks";
import AboutUs from "./AboutUs";
import { useFormik } from "formik";
import * as yup from "yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { defaultDateFormat } from "../../utils/helpers";
import { logoutUser, setUserDetails } from "../../redux/actions/userActions";
import { setLoginState, setPopupState } from "../../redux/actions/appActions";
import { bindActionCreators } from "redux";

const Home = (props) => {
  const {loginPopup } = props?.popupState || {};
  const {user} = props;
  const isLoggedIn = Boolean(user?.useremail)
  
  console.log("user", user)
  console.log("isLoggedIn", isLoggedIn)
  console.log("loginPopup", loginPopup)

  const history = useHistory();
  const [showTravele, setShowTravele] = useState(false); 

  // const history = useHistory();


  const { handleSubmit, handleChange, setFieldValue, values, touched, errors, handleBlur } =
    useFormik({
      initialValues: {
        from: "",
        to: "",
        date: new Date(),
      },
      validationSchema: yup.object({
        from: yup.string().required("Should not be empty."),
        to: yup.string().required("Should not be empty."),
      }),
      onSubmit: async (values) => {
        values.date = defaultDateFormat(values.date);
        history.push({ pathname: "/traveller-list", state: { filter: values } });
      },
    });

  const readMore = () => {
    history.push("/request");
  };

  const setPopupState = useCallback(
    (state) => {
      props.setPopupState(state);
      if (window.location.href.includes("forgot_password"))
        history.push({
          pathname: "/",
          state: { redirectTo: "/forgot_password" },
        });
    },
    [props.setPopupState]
  );

  const cardData = [
    {
      img: driver,
      title: "Traveler",
      heading: "Optimize your spare luggage allowance",
      text: "Use your spare luggage allowance, help others, earn money by traveling and participate in saving the planet",
      buttonText: "How? Read more",
      handleText: readMore,
    },
    {
      img: sender,
      title: "Sender",
      heading: "Get the fastest and cheapest delivery",
      text: "Find the travelers, request them, get the fastest voluntary shipping service and participate in saving the planet",
      buttonText: "How? Read more ",
      handleText: readMore,
    },
  ];


  const addtravel = () => history.push("/addtravel");

  return (
    <div className="homeWrapper">
      <div className="picture" style={{ backgroundImage: `url(${homescreen})` }}>
        <div className="homeInner">
   
          <AppBtn text="Find a traveler" onClick={() => setShowTravele(true)} />
          {!isLoggedIn && (
            <AppBtn text="Add my travel" onClick={() => setPopupState({loginPopup: true})} />
          )}
          {isLoggedIn && (
            <AppBtn text="Add my travel" onClick={addtravel} />
          )}
        </div>
      </div>
 
      <div className="infoCards" id="infocard">
        {cardData.map((items, index) => {
          return (
            <Cards
              key={index}
              img={items.img}
              title={items.title}
              heading={items.heading}
              text={items.text}
              buttonText={items.buttonText}
              handleText={items.handleText}
              style={{ backgroundImage: `url(${smallImage})` }}
            />
          );
        })}
      </div>

      <HowItWorks />
      <AboutUs />

      {/* login screen modal */}
      {loginPopup && <ModalLogin />}

      {/* traveler screen modal    */}
      {showTravele && (
        <Modal
          closeModal={() => setShowTravele(false)}
          props
          submitModal={handleSubmit}
          buttontext="Search"
          title="Find traveler"
        >
          <form onSubmit={handleSubmit}>
            <FormFields
              type="location"
              id="from"
              name="from"
              placeholder="From"
              value={values.from}
              onChange={(address) => setFieldValue("from", address)}
              onSelect={(address) => setFieldValue("from", address)}
              onBlur={handleBlur}
            />
            {touched.from && errors.from ? (
              <span className="errorMessage">{errors.from}</span>
            ) : null}
            <FormFields
              type="location"
              id="to"
              name="to"
              placeholder="To"
              value={values.to}
              onChange={(address) => setFieldValue("to", address)}
              onSelect={(address) => setFieldValue("to", address)}
              onBlur={handleBlur}
            />
            {touched.to && errors.to ? <span className="errorMessage">{errors.to}</span> : null}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd MMM yyyy"
                value={values.date}
                key="date"
                onChange={(val) => {
                  setFieldValue("date", val);
                }}
                minDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="date"
                    onBlur={handleBlur}
                    className="datePickerField"
                  />
                )}
              />
            </LocalizationProvider>
            {touched.date && errors.date ? (
              <span className="errorMessage">{errors.date}</span>
            ) : null}
          </form>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {...state.app, ...state.userdetail};
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch),
  logOut: bindActionCreators(logoutUser, dispatch),
  setPopupState: bindActionCreators(setPopupState, dispatch),
  setLoginState: bindActionCreators(setLoginState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
