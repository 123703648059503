import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Login,
  Home,
  Request,
  FindTravelor,
  TravellerList,
  MyDeliveries,
  MyTravels,
  DeliveryDetail,
  Account,
  NotFound,
} from "../Views";
import About from "../Views/About/About";
import Contact from "../Views/Contact/Contact";
import TravelDetail from "../Views/MyTravels/TravelDetail";
import { Header } from "../Components/Common";
import ForgotPassword from "../Views/ResetPassword/ForgotPassword";
import ResetPassword from "../Views/ResetPassword/ResetPassword";
import VerifyEmail from "../Views/ResetPassword/VerifyEmail";
import Notification from "../Views/Notification";
import Inbox from "../Views/Chat/Inbox";
import Chat from "../Views/Chat/Chat";
import VerifyPhoneNumber from "../Views/Account/VerifyContactNumber";
import Feedback from "../Views/Home/Feedback";
import TermsConditions from "../Views/Home/TermsConditions";
import PrivacyPolicy from "../Views/Home/PrivacyPolicy";
import AddTravellerSteps from "../Views/AddTraveller/AddTravellerSteps";

class Routes extends Component {
  renderWithHeader(Component) {
    return () => (
      <>
        <Header />
        <Component />
      </>
    );
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={this.renderWithHeader(Home)} />
          <Route exact path="/login" component={this.renderWithHeader(Login)} />
          <Route exact path="/home" component={this.renderWithHeader(Home)} />
          <Route exact path="/request/:id" component={this.renderWithHeader(Request)} />
          <Route exact path="/find-traveler" component={this.renderWithHeader(FindTravelor)} />
          <Route exact path="/traveller-list" component={this.renderWithHeader(TravellerList)} />
          <Route exact path="/my-parcels" component={this.renderWithHeader(MyDeliveries)} />
          <Route exact path="/my-travels" component={this.renderWithHeader(MyTravels)} />
          <Route
            exact
            path="/delivery-detail/:id"
            component={this.renderWithHeader(DeliveryDetail)}
          />
          <Route exact path="/travel-detail/:id" component={this.renderWithHeader(TravelDetail)} />
          <Route exact path="/account" component={this.renderWithHeader(Account)} />
          <Route exact path="/aboutus" component={this.renderWithHeader(About)} />
          <Route exact path="/contact-us" component={this.renderWithHeader(Contact)} />
          <Route exact path="/inbox" component={this.renderWithHeader(Inbox)} />
          <Route exact path="/chat/:id" component={this.renderWithHeader(Chat)} />
          <Route
            exact
            path="/verifyPhoneNumber"
            component={this.renderWithHeader(VerifyPhoneNumber)}
          />
          <Route exact path="/addtravel" component={this.renderWithHeader(AddTravellerSteps)} />
          <Route exact path="/forgot_password" component={this.renderWithHeader(ForgotPassword)} />
          <Route
            exact
            path="/reset_password/:token"
            component={this.renderWithHeader(ResetPassword)}
          />
          <Route exact path="/verify_email/:token" component={this.renderWithHeader(VerifyEmail)} />
          <Route exact path="/notification/:id" component={this.renderWithHeader(Notification)} />
          <Route exact path="/support" component={this.renderWithHeader(Contact)} />

          <Route exact path="/feedback" component={this.renderWithHeader(Feedback)} />
          <Route exact path="/terms" component={this.renderWithHeader(TermsConditions)} />
          <Route exact path="/privacy" component={this.renderWithHeader(PrivacyPolicy)} />

          <Route exact path="*" component={this.renderWithHeader(NotFound)} />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
