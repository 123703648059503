import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyD9PQoVk5CyQatZZhejTRaGE7ljx9TAoWU",
  authDomain: "greenshipexservice.firebaseapp.com",
  projectId: "greenshipexservice",
  storageBucket: "greenshipexservice.appspot.com",
  messagingSenderId: "380256347223",
  appId: "1:380256347223:web:5c4b8041dad8e2438faf12",
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
