import { apiURL } from "../config";

export const UsersAPIs = {
  GET_USER: `${apiURL}api/user`,
  GET_ACOUNT_DETAILS: `${apiURL}api/user/account_details`,
  REGISTER_USER: `${apiURL}api/user`,
  UPDATE_USER: `${apiURL}api/user`,
  FORGOT_PASS: `${apiURL}api/user/forgot_password`,
  RESET_PASS: `${apiURL}api/user/reset_password`,
  CHANGE_PASS: `${apiURL}api/user/change_password`,
  VERIFY_EMAIL: `${apiURL}api/user/verify_email`,
  UPLOAD_PROFILE_PICTURE: `${apiURL}api/user/upload-avatar`,
};

export const PackageAPIs = {
  GET_PACKAGES: `${apiURL}api/package/packages`,
  GET_PACKAGE: `${apiURL}api/package`,
  GET_TRAVELS: `${apiURL}api/package/travels`,
  GET_TRAVELLERS: `${apiURL}api/package/travellers`,
  GET_ALL_TRAVELERS: `${apiURL}api/package/alltravellers`,
  POST_PCAKAGE: `${apiURL}api/package`,
  ACCEPT_PCAKAGE: `${apiURL}api/package/accept`,
  DECLINE_PCAKAGE: `${apiURL}api/package/decline`,
};

export const TravelAPIs = {
  GET_TRAVEL: `${apiURL}api/travel/`,
  GET_MY_TRAVELS: `${apiURL}api/travel/getMyTravels`,
  GET_TRAVELLERS: `${apiURL}api/travel/travellers`,
  POST_TRAVEL: `${apiURL}api/travel/`,
  PUT_TRAVEL: `${apiURL}api/travel/`,
  DELETE_TRAVEL: `${apiURL}api/travel/`,
};

export const MessageAPIs = {
  GET_MESSAGE: `${apiURL}api/message`,
  POST_MESSAGE: `${apiURL}api/message`,
};

export const ChatAPIs = {
  GET_CHATS: `${apiURL}api/chat`,
  GET_CHAT: (id) => `${apiURL}api/chat/${id}`,
};

export const MyNotificationAPIs = {
  GET_NOTIFICATION: `${apiURL}api/notification`,
  GET_MY_NOTIFICATION: `${apiURL}api/notification/getMyNotifications`,
  MARK_AS_READ: `${apiURL}api/notification/markAsRead`,
};
