import React, { useEffect,useMemo } from "react";
import { useHistory } from "react-router-dom";
import {  FormFields, AppBtn } from "../../Components/Common";
import { useFormik } from "formik";
import * as yup from "yup";
import { ResetPassword as resetPassword } from "../../services/userServices";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

const ResetPassword = (props) => {
  let { token } = useParams();

  const history = useHistory();
  const user = useMemo( () => props.user, [props.user]);

  useEffect(() => {
    if (user._id) {
      history.push("/home");
    }
  }, []);

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } = useFormik({
    initialValues: {
      newPass: "",
      verifyPass: "",
    },
    validationSchema: yup.object({
      newPass: yup.string().required("Should not be empty."),
      verifyPass: yup
        .string()
        .required("Should not be empty.")
        .oneOf([yup.ref("newPass")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      const result = await resetPassword(values, token)
        .then((response) => {
          return response.data;
        })
        .catch((err) => null);
      if (result) history.push("/home");
    },
  });

  return (
    <div className="loginWrapper">
      <div className="loginInner">
        <div className="container">
          <h1>Reset Password</h1>

          <form>
            <FormFields
              type="password"
              id="newPass"
              name="newPass"
              placeholder="New Password"
              value={values.newPass}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.newPass && errors.newPass ? (
              <span className="errorMessage">{errors.newPass}</span>
            ) : null}
            <FormFields
              type="password"
              id="verifyPass"
              name="verifyPass"
              placeholder="Verify Password"
              value={values.verifyPass}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.verifyPass && errors.verifyPass ? (
              <span className="errorMessage">{errors.verifyPass}</span>
            ) : null}
            <AppBtn text="Confirm" onClick={handleSubmit} />
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {...state.userdetail};
};
export default connect(mapStateToProps, null)(ResetPassword);
