import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormFields, AppBtn } from "../../Components/Common";
import { useFormik } from "formik";
import * as yup from "yup";
import { ForgotPassword as forgotPassword } from "../../services/userServices";
import { toast } from "react-toastify";
import { logoutUser, setUserDetails } from "../../redux/actions/userActions";
import { setLoginState, setPopupState } from "../../redux/actions/appActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const ForgotPassword = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (Boolean(props.user?.useremail)) {
      history.push("/home");
    }
    props.setPopupState({})
  }, []);

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup.string().email("Invalid email formate.").required("Should not be empty."),
    }),
    onSubmit: async (values) => {
      const result = await forgotPassword(values)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          toast.error(err.response.data);
          return null;
        });

      if (result) {
        toast.success("Reset link has been sent on your email. Please check your email");
        history.push("/home");
      }
    },
  });

  return (
    <div className="loginWrapper">
      <div className="loginInner">
        <div className="container">
          <h1>Forgot Password</h1>
          <div>
            <FormFields
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email ? (
              <span className="errorMessage">{errors.email}</span>
            ) : null}
            <AppBtn text="Confirm" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {...state.app,...state.userdetail};
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch),
  logOut: bindActionCreators(logoutUser, dispatch),
  setPopupState: bindActionCreators(setPopupState, dispatch),
  setLoginState: bindActionCreators(setLoginState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
