import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { ModalLogin } from "../index";
import ModalRegister from "../Modal/ModalRegister";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  notification,
  avatar,
  notificationUnread,
} from "../../../resources/images";
import {
  GetMyNotifications,
  MarkAsRead,
} from "../../../services/notificationServices";
import {
  defaultDateFormat,
  getFormatedChatDateTime,
} from "../../../utils/helpers";
import { AppBtn, FormFields, Modal } from "../index";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Divider, Menu, MenuItem, TextField, IconButton } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MenuIcon from "@mui/icons-material/Menu";
import { RiUser3Fill } from "react-icons/ri";
import Avatar from "../avatar";
import { logoutUser, setUserDetails } from "../../../redux/actions/userActions";
import {
  setLoginState,
  setPopupState,
} from "../../../redux/actions/appActions";
import { bindActionCreators } from "redux";

const Header = (props) => {
  const { loginPopup, registerPopup, findTravelPopup } =
    props?.popupState || {};
  const { user } = props;

  const isLoggedIn = Boolean(user?.useremail);

  const [isShowNav, setIsShowNav] = useState(false);
  const [isShowNotifications, setIsShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadInNotifications();
  }, []);

  const loadInNotifications = async () => {
    const result = await GetMyNotifications().catch((error) => {
      console.log({ error });
      return null;
    });
    if (result && result.data) setNotifications(result.data);
  };

  const markAllAsRead = async () => {
    await MarkAsRead().catch((error) => {
      console.log({ error });
      return null;
    });
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      from: "",
      to: "",
      date: new Date(),
    },
    validationSchema: yup.object({
      from: yup.string().required("Should not be empty."),
      to: yup.string().required("Should not be empty."),
    }),
    onSubmit: async (values) => {
      values.date = defaultDateFormat(values.date);
      history.push({ pathname: "/traveller-list", state: { filter: values } });
      setPopupState({ findTravelPopup: false });
    },
  });

  let Name = useMemo(() => {
    return `${user?.name} ${user?.firstname}`;
  }, [user]);

  const setPopupState = useCallback(
    (state) => {
      props.setPopupState(state);
      if (window.location.href.includes("forgot_password"))
        history.push({
          pathname: "/",
          state: { redirectTo: "/forgot_password" },
        });
    },
    [props.setPopupState]
  );

  const addtravel = () => history.push("/addtravel");
  return (
    <>
      <div className="headerWrapper">
        <span
          className={` ${isShowNav ? "crossIcon" : "MobileBarNav"}`}
          onClick={() => setIsShowNav(!isShowNav)}
        >
          {isShowNav ? <AiOutlineClose /> : <FaBars />}
        </span>
        <span className="siteLogo">
          <Link to="/">Green ShipEx</Link>
        </span>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span className={`${isShowNav ? "mobileNavOpen" : "mobileNav"}`}>
            <ul>
              {isLoggedIn ? (
                <li onClick={addtravel}>Add Travel</li>
              ) : (
                <li
                  onClick={() =>
                    setPopupState({ loginPopup: true }, setIsShowNav(false))
                  }
                >
                  Add Travel
                </li>
              )}

              <li
                onClick={() =>
                  setPopupState({ findTravelPopup: true }, setIsShowNav(false))
                }
              >
                Find Traveler
              </li>
              {/* <li onClick={() => history.push("/support")}>Support</li> */}

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {isLoggedIn ? (
                  <>
                    <MenuItem className="header_dropMenuItem header_userName">
                      {Name}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => history.push("/inbox")}
                      className="header_dropMenuItem"
                    >
                      Inbox
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => history.push("/my-travels")}
                      className="header_dropMenuItem"
                    >
                      My Travels
                    </MenuItem>
                    <MenuItem
                      onClick={() => history.push("/my-parcels")}
                      className="header_dropMenuItem"
                    >
                      My Parcels
                    </MenuItem>
                    <MenuItem
                      onClick={() => history.push("/account")}
                      className="header_dropMenuItem"
                    >
                      Account
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        toast.info("Logged out!");
                        props.setUserDetails({});
                        history.push("/home");
                      }}
                      className="header_dropMenuItem"
                    >
                      Logout
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => setPopupState({ registerPopup: true })}
                      className="header_dropMenuItem"
                    >
                      Register
                    </MenuItem>
                    <MenuItem
                      onClick={() => setPopupState({ loginPopup: true })}
                      className="header_dropMenuItem"
                    >
                      Login
                    </MenuItem>
                  </>
                )}
              </Menu>
            </ul>
          </span>

          <div className="avatarNotifWrapper">
            {isLoggedIn ? (
              <>
                <div className="notificationsWrapper">
                  <span>
                    <img
                      src={
                        notifications &&
                        notifications.some((item) => !item.seen)
                          ? notificationUnread
                          : notification
                      }
                      alt="Notification"
                      onClick={() => {
                        setIsShowNotifications(!isShowNotifications);
                        notifications.some((item) => !item.seen) &&
                          markAllAsRead();
                      }}
                    />
                    {/* <span
                      className={
                        notifications && notifications.some((item) => !item.seen)
                          ? "needOpening"
                          : ""
                      }
                    ></span> */}
                  </span>
                  <div
                    className={`notificationsBody ${
                      isShowNotifications ? "showNotificationsBody" : ""
                    }`}
                  >
                    <span className="header">
                      <span>
                        Notifications
                        <div
                          className={
                            notifications.some((item) => !item.seen)
                              ? "needOpening"
                              : ""
                          }
                        ></div>
                      </span>
                    </span>
                    <div
                      style={{
                        maxHeight: "499px",
                        overflowY: "auto",
                      }}
                    >
                      {notifications.map((item) => (
                        <div
                          onClick={() =>
                            item?.message.toLowerCase().includes("message")
                              ? history.push(`/chat/${item?.chatId}`)
                              : history.push(`/notification/${item?._id}`)
                          }
                          className={`notificationsItem ${
                            item?.seen ? "" : "newNotification"
                          }`}
                        >
                          <div>
                            <Avatar
                              customStyle={{
                                height: "40px",
                                borderRadius: "50%",
                                overflow: "hidden",
                                width: "40px",
                                marginRight: "15px",
                                fontSize: "20px",
                              }}
                              imageLink={
                                item?.fromUser?.profilePicture
                                  ? `/${item?.fromUser?.profilePicture}`
                                  : null
                              }
                              shortName={
                                item?.fromUser?.name?.[0] +
                                item?.fromUser?.firstname?.[0]
                              }
                            />
                            <div className="itemBody">
                              <div className="itemHeader">
                                <span>
                                  {getFormatedChatDateTime(
                                    item.createdAt,
                                    true
                                  )}
                                  {/* 10 min ago */}
                                </span>
                                <p>
                                  {item?.fromUser?.firstname}{" "}
                                  {item?.fromUser?.name}
                                </p>
                              </div>
                              <div className="info">
                                {item?.message.toLowerCase().includes("message")
                                  ? `${item?.message} from ${item?.fromUser?.name}`
                                  : item?.message}
                              </div>
                              <div className="rout">
                                {item?.travel?.from && (
                                  <>
                                    {item?.travel?.from} to {item?.travel?.to}
                                    <br />
                                  </>
                                )}
                                on{" "}
                                {defaultDateFormat(item?.travel?.dateOfPickup)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="viewAllNotificationBtn">View All</div>
                  </div>
                </div>
                <div className="avatar" onClick={handleClick}>
                  <Avatar
                    customStyle={{
                      fontSize: "20px",
                    }}
                    imageLink={
                      user?.profilePicture ? `/${user?.profilePicture}` : null
                    }
                    shortName={user?.name?.[0] + user?.firstname?.[0]}
                  />
                </div>
              </>
            ) : null}
          </div>

          {!isLoggedIn && (
            <IconButton
              onClick={handleClick}
              className="userProfile"
              size="small"
              sx={{ ml: 2, cursor: "pointer" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <RiUser3Fill />
            </IconButton>
          )}
        </div>
      </div>
      {/* Login popup */}
      {loginPopup && <ModalLogin />}

      {/* Register popup */}
      {registerPopup && <ModalRegister />}

      {/* traveler screen modal */}
      {findTravelPopup && (
        <Modal
          closeModal={() => setPopupState({ findTravelPopup: false })}
          submitModal={handleSubmit}
          buttontext="Search"
          title="Find traveler"
        >
          <form onSubmit={handleSubmit}>
            <FormFields
              type="location"
              id="from"
              name="from"
              placeholder="From"
              value={values.from}
              onChange={(address) => setFieldValue("from", address)}
              onSelect={(address) => setFieldValue("from", address)}
              onBlur={handleBlur}
            />
            {touched.from && errors.from ? (
              <span className="errorMessage">{errors.from}</span>
            ) : null}
            <FormFields
              type="location"
              id="to"
              name="to"
              placeholder="To"
              value={values.to}
              onChange={(address) => setFieldValue("to", address)}
              onSelect={(address) => setFieldValue("to", address)}
              onBlur={handleBlur}
            />
            {touched.to && errors.to ? (
              <span className="errorMessage">{errors.to}</span>
            ) : null}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                inputFormat="dd MMM yyyy"
                value={values.date}
                key="date"
                onChange={(val) => {
                  setFieldValue("date", val);
                }}
                minDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="date"
                    onBlur={handleBlur}
                    className="datePickerField"
                  />
                )}
              />
            </LocalizationProvider>
            {touched.date && errors.date ? (
              <span className="errorMessage">{errors.date}</span>
            ) : null}
          </form>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state.app, ...state.userdetail };
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch),
  logOut: bindActionCreators(logoutUser, dispatch),
  setPopupState: bindActionCreators(setPopupState, dispatch),
  setLoginState: bindActionCreators(setLoginState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
