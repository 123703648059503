import React from 'react';

function Cards({img, title, heading, text, buttonText, handleText, style}) {
    return (
        <div className="cardWrapper" style={style}>
            
            <span className="cardImage">
                <img src={img} alt="" />
                <span className="cardTitle">{title}</span>
            </span>

            <span className="cardHeading">{heading}</span>
            <span className="cardBodyText">{text}</span>
            <span className="cardBtnWrapper">
                <button onClick={handleText}>{buttonText}</button>
            </span>            
        </div>
    );
}

export default Cards;