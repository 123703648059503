import { TravelAPIs } from "../api/apiEndPoints";
import { request } from "../api/axiosDefault";

export const getMyTravels = (params) =>
  request({ method: "get", url: TravelAPIs.GET_MY_TRAVELS, params });

export const getTravellers = (params) =>
  request({ method: "get", url: TravelAPIs.GET_TRAVELLERS, params });

export const postTravel = (data) => request({ method: "post", url: TravelAPIs.POST_TRAVEL, data });

export const getTravel = (id) =>
  request({ method: "get", url: TravelAPIs.GET_TRAVEL, params: { id } });
