import React, {  useEffect, useState } from "react";
import TravelsCard from "./TravelsCard";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { defaultDateFormat } from "../../utils/helpers";
import { getMyTravels } from "../../services/travelServices";
import moment from "moment";

const TravelsList = (props) => {
  const [data, setData] = useState([]);
  const [filterdata, setFilterData] = useState([]);

  const history = useHistory();
  const params = { userid: localStorage.getItem("_id") };

  let sum = filterdata.reduce(function (prev = 0, current) {
    if (current?.packageDetails?.approvalStatus === "Approved" && new Date(current?.dateOfDelivery).getTime() < moment().add("day",-1).toDate().getTime()) return prev + parseFloat(current.charges);
    else return prev;
  }, 0);

  useEffect(() => {
    packagelist(params);
  }, []);

  const traveldetail = (itemid) => history.push(`/travel-detail/${itemid}`);
  const packagelist = (params) =>
    getMyTravels(params).then((response) => {
      if(response.data)
        response.data.sort((a,b) => new Date(b?.dateOfDelivery).getTime() - new Date(a?.dateOfDelivery).getTime())
      setData(response.data);
      setFilterData(response.data);
    });

  const getFutureTravels = (filter) => {
    let result = data.filter((data) => {
      if (filter === "future") {
        return new Date(data.dateOfDelivery) >= Date.now();
      } else if (filter === "past") {
        return new Date(data.dateOfDelivery) < Date.now();
      } else {
        return data;
      }
    });
    setFilterData(result);
  };

  return (
    <div className="mydeliveriesWrapper">
      <div className="deliveriesInner">
        <div className="deliveryCardWrapper">
          <div className="cardLeft">
            <span className="deliveryEarned">
              Gross Earnings: <i>{sum} €</i>
            </span>
          </div>

          <div className="filterBtnWrapper">
            <button name="groupOptions" onClick={() => getFutureTravels("all")}>
              All
            </button>
            <button name="groupOptions" onClick={() => getFutureTravels("future")}>
              Future
            </button>
            <button name="groupOptions" onClick={() => getFutureTravels("past")}>
              Past
            </button>
          </div>
        </div>
        {filterdata &&
          filterdata.map((items, index) => {
            return (
              <TravelsCard
                key={index}
                id={items._id}
                img={items.travelerDetails?.profilePicture}
                name={`${items.travelerDetails?.name} ${items.travelerDetails?.firstname}`}
                requesttext={items.requesttext}
                date={`${defaultDateFormat(items.dateOfPickup)}`}
                weight={items.weight}
                amount={items?.packageDetails?.approvalStatus === "Approved" ? items.charges : 0}
                deliverspot={items.to}
                placeofpickup={items.from}
                submitrequest={traveldetail}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStatetoProps = (userdetail) => {
  const { userDetail } = userdetail;
  return { userDetail };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(TravelsList);
