import React, { useEffect } from "react";
import { Modal, FormFields } from "../index";
import { useFormik } from "formik";
import * as yup from "yup";
import { ChangePassword } from "../../../services/userServices";
import { toast } from "react-toastify";

function ModelChangePassword({ handleCLose }) {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } = useFormik({
    initialValues: {
      oldPass: "",
      newPass: "",
      verifyPass: "",
    },
    validationSchema: yup.object({
      oldPass: yup.string().required("Should not be empty."),
      newPass: yup
        .string()
        .required("Should not be empty.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      verifyPass: yup
        .string()
        .required("Should not be empty.")
        .oneOf([yup.ref("newPass")], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      await ChangePassword(values)
        .then((response) => {
          console.log(response);
          if (response.status == 200 && response.data == "OK")
            toast.success("Password successfully updated!");
          else
            toast.error(
              response.data == "Invalid Current Password!"
                ? "Invalid Current Password!"
                : "An error occured"
            );
        })
        .catch((err) => {
          toast.error("An error occured");
        });
    },
  });

  return (
    <div>
      <Modal
        closeModal={handleCLose}
        submitModal={handleSubmit}
        buttontext="Confirm"
        title="Change Password"
      >
        <form>
          <FormFields
            type="password"
            id="oldPass"
            name="oldPass"
            placeholder="Current password"
            value={values.oldPass}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.oldPass && errors.oldPass ? (
            <span className="errorMessage">{errors.oldPass}</span>
          ) : null}

          <FormFields
            type="password"
            id="newPass"
            name="newPass"
            placeholder="New password"
            value={values.newPass}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.newPass && errors.newPass ? (
            <span className="errorMessage">{errors.newPass}</span>
          ) : null}

          <FormFields
            type="password"
            id="verifyPass"
            name="verifyPass"
            placeholder="Verify password"
            value={values.verifyPass}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.verifyPass && errors.verifyPass ? (
            <span className="errorMessage">{errors.verifyPass}</span>
          ) : null}
        </form>
      </Modal>
    </div>
  );
}

export default ModelChangePassword;
