import React from 'react';

function PrivacyPolicy(props) {
    return (
        <div className="traveleListWrapper">
            <div className="traveleListBody">
           <h1>Privacy Policy</h1> 
           <p>
                We are not responsible of any data or information you share with traveler/sender through chat or any other way. We also do not share your data with any third party.
            </p>
            <p>
                By browsing our website, you are conforming that you accept the above terms and Conditions, and you understand that this project is curently non profit and only for research purpose. 
            </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;