import React, { useEffect } from "react";
import { toast } from "react-toastify";

function NotFound(props) {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  return (
    <div className="main-container notFoundWrapper">
      <span>&#9785;</span> Page not found.
    </div>
  );
}

export default NotFound;
