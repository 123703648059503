import React from 'react';
import {BsX} from 'react-icons/bs'

function Modal({closeModal, submitModal, children, title, handleRegisterNow,  buttontext, type}) {
    return (        
        <div className="overlay">
            <div className="popupWrapper">
            <h1>{title}</h1>
                <span onClick={closeModal} className="popupClose"><BsX /></span>
                
                <div className="popupInnerBody">
                    {children}
                    <div className="popupFooter">
                        <button type={type} onClick={submitModal}>{buttontext}</button>
                    </div> 
                </div>
                {title == "Login" && <div  className="loginRegisterNowBtn">
                    Don’t have an account? <span onClick={handleRegisterNow}>Register Now</span>
                </div>}
            </div>
            
        </div>
      
    );
}

export default Modal;