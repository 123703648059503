import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaChevronLeft, FaMapMarkerAlt } from "react-icons/fa";
import { GrPrint, GrCloudDownload } from "react-icons/gr";
import { Link, useParams } from "react-router-dom";
import { getTravel } from "../../services/travelServices";
import { defaultDateFormat, getFormatedChatDateTime } from "../../utils/helpers";
import Avatar from "../../Components/Common/avatar";

const TravelDetail = (props) => {
  const [data, setData] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    getTravel(id).then((response) => {
      setData(response.data);
    });
  }, []);

  const defaultdate = data?.dateOfPickup;

  return (
    <div className="mydeliveriesWrapper">
      <div className="detailsInner">
        <h1>
          <i>
            <Link to="/my-travels">
              <FaChevronLeft />
            </Link>
          </i>
          Travel details
        </h1>
        {data ? (
          <>
            <div className="contsctDetailWrapper">
              <div className="addressDetail">
                <div>
                  <span>Travel Id:</span>
                  <p>{data?._id}</p>
                </div>
                <div>
                  <span>Date:</span>
                  <p>{defaultdate && `${defaultDateFormat(defaultdate)}`}</p>
                </div>
                <div>
                  <span>Charges:</span>
                  <p>{data?.charges} €</p>
                </div>
                <div>
                  <span>Pick-up:</span>
                  <p>{data?.from}</p>
                </div>
                <div>
                  <span>Drop-off:</span>
                  <p>{data?.placeOfDropoff}</p>
                </div>
                <div>
                  <span>Weight:</span>
                  <p>{data?.weight} KG</p>
                </div>
              </div>
              <span className="avatar">
                <i>
                  <Avatar
                    customStyle={{
                      fontSize: "34px",
                    }}
                    imageLink={
                      data?.travelerDetails?.profilePicture
                        ? `/${data?.travelerDetails?.profilePicture}`
                        : null
                    }
                    shortName={data?.travelerDetails?.name?.[0] + data?.travelerDetails?.firstname?.[0]}
                  />
                </i>
                <span>
                  {data?.travelerDetails?.firstname} {data?.travelerDetails?.name}
                </span>
              </span>
            </div>

            <div className="cardmiddle">
              <span className="leftLocation">{data?.placeOfPickup}</span>
              <div>
                <span className="travelerDestination">
                  <i>{data?.dateOfPickup && `${getFormatedChatDateTime(data?.dateOfPickup)}`}</i>
                  <i>{data?.dateOfDelivery && `${getFormatedChatDateTime(data?.dateOfDelivery)}`}</i>
                </span>
                <span className="travelerLine"></span>
                <span className="travelerDestination">
                  <i>
                    <FaMapMarkerAlt />
                    {data?.from}
                  </i>
                  <i>
                    <FaMapMarkerAlt />
                    {data?.to}
                  </i>
                </span>
              </div>
              <span className="rightLocation">{data?.placeOfDropoff}</span>
            </div>

            {data?.description && (
              <div className="packageDescription">
                <h2>Package Description</h2>
                <p>{data?.description}</p>
              </div>
            )}
          </>
        ) : (
          <span>No data found</span>
        )}

        <div className="packageFooter">
          <GrPrint />
          <GrCloudDownload />
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.userdetail };
};


export default connect(mapStatetoProps)(TravelDetail);
