import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaChevronLeft, FaMapMarkerAlt } from "react-icons/fa";
import { GrPrint, GrCloudDownload } from "react-icons/gr";
import { Link, useParams } from "react-router-dom";
import * as userActions from "../../redux/actions/userActions";
import { getPackage } from "../../services/packageServices";
import { bindActionCreators } from "redux";
import { defaultDateFormat, getFormatedChatDateTime } from "../../utils/helpers";
import Avatar from "../../Components/Common/avatar";

const DeliveryDetail = (props) => {
  const [data, setData] = useState([]);

  let { id } = useParams();

  const params = { id: id };

  const packagedetail = (params) =>
    getPackage(params).then((response) => {
      setData(response.data);
    });

  useEffect(() => {
    packagedetail(params);
  }, []);

  const defaultdate = data?.travel?.dateOfDelivery;

  return (
    <div className="mydeliveriesWrapper">
      <div className="detailsInner">
        <h1>
          <i>
            <Link to="/my-parcels">
              <FaChevronLeft />
            </Link>
          </i>
          Package details
        </h1>
        {data ? (
          <>
            <div className="contsctDetailWrapper">
              <div className="addressDetail">
                {data?.approvalStatus === "Approved" && (
                  <div>
                    <span>Package number:</span>
                    <p>{data?._id}</p>
                  </div>
                )}
                <div>
                  <span>Approval Status:</span>
                  <p>{data?.approvalStatus}</p>
                </div>
                <div>
                  <span>Date:</span>
                  <p>{defaultdate && `${defaultDateFormat(defaultdate)}`}</p>
                </div>
                <div>
                  <span>Charges:</span>
                  <p>{data?.travel?.charges} €</p>
                </div>
                <div>
                  <span>Drop-off:</span>
                  <p>{data?.travel?.from}</p>
                </div>
                <div>
                  <span>Pick-up:</span>
                  <p>{data?.travel?.placeOfDropoff}</p>
                </div>
                <div>
                  <span>Weight:</span>
                  <p>{data?.travel?.weight}</p>
                </div>
              </div>
              <span className="avatar">
                <i>
                  <Avatar
                    customStyle={{
                      fontSize: "34px",
                    }}
                    imageLink={
                      data?.traveler?.profilePicture ? `/${data?.traveler?.profilePicture}` : null
                    }
                    shortName={data?.traveler?.name?.[0] + data?.traveler?.firstname?.[0]}
                  />
                </i>
                <span>
                  {data?.traveler?.firstname} {data?.traveler?.name}
                </span>
              </span>
            </div>

            <div className="cardmiddle">
              <span className="leftLocation">{data?.travel?.placeOfPickup}</span>
              <div>
                <span className="travelerDestination">
                  <i>{data?.travel?.dateOfPickup && `${getFormatedChatDateTime(data?.travel?.dateOfPickup)}`}</i>
                  <i>{data?.travel?.dateOfDelivery && `${getFormatedChatDateTime(data?.travel?.dateOfDelivery)}`}</i>
                </span>
                <span className="travelerLine"></span>
                <span className="travelerDestination">
                  <i>
                    <FaMapMarkerAlt />
                    {data?.travel?.from}
                  </i>
                  <i>
                    <FaMapMarkerAlt />
                    {data?.travel?.to}
                  </i>
                </span>
              </div>
              <span className="rightLocation">{data?.travel?.placeOfDropoff}</span>
            </div>

            {data?.travel?.description && (
              <div className="packageDescription">
                <h2>Package Description</h2>
                <p>{data?.travel?.description}</p>
              </div>
            )}
          </>
        ) : (
          <span>No data found</span>
        )}

        <div className="packageFooter">
          <GrPrint />
          <GrCloudDownload />
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (userdetail) => {
  const { userDetail } = userdetail;
  return { userDetail };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(DeliveryDetail);
