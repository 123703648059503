import React from "react";

function Avatar({ imageLink, classNames, shortName, customStyle }) {
  return (
    <div
      className={`defaultAvatar ${classNames}`}
      style={{
        ...(customStyle || {}),
        backgroundImage: `url("${imageLink}")`,
      }}
    >
      <span>{imageLink ? null : shortName ? shortName : ""}</span>
    </div>
  );
}

export default Avatar;
