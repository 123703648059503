import {actionTypes} from "../actions/userActions"

export const INITIAL_STATE = {
      user: {}
}

const reducer = (state, action) => {
    if(!state) state = INITIAL_STATE;

    switch(action.type) {
        case actionTypes.LOGOUT_USER:
            return {
                ...INITIAL_STATE
            };
        case actionTypes.SET_USER_DETAILS:
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
