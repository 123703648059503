import { UsersAPIs } from "../api/apiEndPoints";
import { request } from "../api/axiosDefault";

export const getUser = (params) => request({ method: "get", url: UsersAPIs.GET_USER, params });

export const signIn = (params) => request({ method: "get", url: UsersAPIs.GET_USER, params });

export const RegisterUser = async (data) =>
  request({ method: "post", url: UsersAPIs.REGISTER_USER, data });

export const GetAccountDetails = (params) =>
  request({ method: "get", url: UsersAPIs.GET_ACOUNT_DETAILS, params });

export const UpdateUser = (data) => request({ method: "put", url: UsersAPIs.UPDATE_USER, data });

export const ForgotPassword = (data) =>
  request({ method: "post", url: UsersAPIs.FORGOT_PASS, data });

export const ResetPassword = (data, token) =>
  request({ method: "post", url: UsersAPIs.RESET_PASS, data, params: { token } });

export const ChangePassword = (data) =>
  request({ method: "post", url: UsersAPIs.CHANGE_PASS, data });

export const VerifyEmail = (token) =>
  request({ method: "get", url: UsersAPIs.VERIFY_EMAIL, params: { token } });
