import React from 'react';

const Textarea = ({value, placeholder, children}) => {
    return (
        <div className="formFieldsWrapper">
            <textarea 
                value={value} 
                placeholder={placeholder} 
                
                autoComplete = "off" 
            />
            <span className="errorMessage">
                {children}
            </span>
        </div>
    );
};

export default Textarea;