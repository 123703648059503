import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Header, FormFields, AppBtn } from "../../Components/Common/index";
import ContactUs from "../Home/ContactUs";

const Contact = () => {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  return (
    <div className="loginWrapper">
      <ContactUs />
    </div>
  );
};

export default Contact;
