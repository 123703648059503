import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Header } from ".././../Components/Common/index";

const FindTravelor = () => {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  return (
    <div className="findTravelerWrapper">
      <h1> Find Travelor</h1>
    </div>
  );
};

export default FindTravelor;
