import React from "react";

const DeliveryCard = ({
  id,
  img,
  name,
  charges,
  date,
  imgURL,
  nameURL,
  requesttext,
  submittrack,
  tracktext,
  submitrequest,
  placeofdelivery,
  placeofpickup,
}) => {
  return (
    <div className="deliveryCardWrapper">
      <div className="cardLeft">
        <span className="userImage">
          <img src={img} alt="" onClick={imgURL} />
        </span>
        <span className="deliveryName">
          <span onClick={nameURL}>{name}</span>
        </span>
        <span className="deliveryEarned">
          Charges: <i>{charges} €</i>
        </span>
      </div>
      <div className="cardmiddle">
        <span className="deliveryDate">Date: {date}</span>
        <span className="travelerLine"></span>
        <span className="travelerDestination">
          <i>{placeofpickup}</i> <i>{placeofdelivery}</i>
        </span>
      </div>
      <div className="cardRight">
        <span className="travelerBtnWrapper">
          <button onClick={() => submitrequest(id)}>{requesttext}</button>
          <button onClick={submittrack}>{tracktext}</button>
        </span>
      </div>
    </div>
  );
};

export default DeliveryCard;
