import React, { useEffect, useState ,useMemo} from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./VerifyContactNumber.css";
import { SuccessIcon } from "../../resources/images";
import { useHistory } from "react-router";
import { authentication } from "./firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import moment from "moment";
import MuiPhoneNumber from "material-ui-phone-number";
import { UpdateUser } from "../../services/userServices";
import { toast } from "react-toastify";
import { connect } from "react-redux";

function VerifyPhoneNumber(props) { 
  const user = useMemo( () => props.user, [props.user]);
  const history = useHistory();
  const [currentSlid, setCurrentSlid] = useState(0);
  const [mynumber, setnumber] = useState(user.phonenumber);
  const [otp, setotp] = useState("      ");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpTimeOut, setOtpTimeOut] = useState(moment());
  const [expiresIn, setExpiresIn] = useState(0);
  const [updateListner, setUpdateListner] = useState(0);
  const [changeNumber, setChangeNumber] = useState(false);

  // Sent OTP
  const sendOTP = () => {
    if (!mynumber || mynumber.length <= 10) return;
    document.querySelector("#recaptcha-container").innerHTML = "";
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          resetTimer();
        },
      },
      authentication
    );

    signInWithPhoneNumber(authentication, mynumber, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
        setCurrentSlid(1);
        resetTimer();
      })
      .catch((error) => {
        setConfirmationResult(null);
        console.log(error);
      });
  };

  const resetTimer = () => {
    setOtpTimeOut(moment());
    setExpiresIn(60);
    setUpdateListner(updateListner < 100 ? updateListner + 1 : 0);
  };

  useEffect(() => {
    const exp = 60 - moment().diff(otpTimeOut, "seconds");

    setExpiresIn(exp > 0 ? exp : 0);
    setTimeout(() => setUpdateListner(updateListner < 100 ? updateListner + 1 : 0), 1000);
  }, [updateListner]);

  // Validate OTP
  const ValidateOtp = () => {
    confirmationResult.confirm(otp).then((res) => {
      UpdateUser({ phonenumber: mynumber, isContactNoVerified: true })
        .then((res) => {
          setCurrentSlid(2);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleNext = () => {
    switch (currentSlid) {
      case 0:
        sendOTP();
        break;
      case 1:
        expiresIn > 0 && ValidateOtp();
        break;
      default:
        return;
    }
  };

  // sub components
  const VerifyPhoneNumberSuccess = () => {
    return (
      <div className="popupBoxWrapper">
        <div>
          <div className="popupBox" style={{ alignItems: "center" }}>
            <AiOutlineClose className="popupCloseIcon" onClick={() => history.push("/")} />
            <img src={SuccessIcon} width={60} />
            <h3>Verified!</h3>
            <div className="popupDescription">
              You have successfully verified your Phone number.
            </div>

            <div className="continueButton" onClick={() => history.push("/")}>
              Go to Homepage
            </div>
          </div>
        </div>
      </div>
    );
  };

  const VerifyPhoneNumberOTP = () => {
    return (
      <div className="popupBoxWrapper">
        <div>
          <div className="popupBox">
            <AiOutlineClose
              className="popupCloseIcon"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  toast.warn("Your phone number is not verified");
                }, 1000);
              }}
            />
            <div className="popupTitle">Phone Verification</div>
            <div className="popupDescription">
              Enter the six digit code that we sent to {mynumber}.
            </div>
            <div className="OTP-inputs">
              <label for="otp-main-input">{otp[0]}</label>
              <label for="otp-main-input">{otp[1]}</label>
              <label for="otp-main-input">{otp[2]}</label>
              <label for="otp-main-input">{otp[3]}</label>
              <label for="otp-main-input">{otp[4]}</label>
              <label for="otp-main-input">{otp[5]}</label>
            </div>
            <div className="inlineSpaceBetween">
              <div className="popupDescription">
                {
                  expiresIn > 0 ? <>
                    The code will be expired in <span style={{ color: "#35B736" }}>{expiresIn}</span>.
                  </> : "The code has been expired, please Resend the code"
                }
              </div>
              <a className="resendCodeBtn" onClick={sendOTP}>
                Resend Code
              </a>
            </div>
            <div id="recaptcha-container"></div>

            <input
              style={{ zIndex: "-1", width: 0, height: 0 }}
              id="otp-main-input"
              type="number"
              value={otp.trim().length > 0 ? Number(otp) : null}
              onChange={(e) => {
                let value = e.target.value;
                value = value.trim();
                const remainingOtpDigits = 6 - value.length;
                if (remainingOtpDigits >= 0) {
                  value += Array(6 - value.length)
                    .fill(" ")
                    .join("");
                  setotp(value);
                }
              }}
            />
            <div
              className={`continueButton ${expiresIn > 0 ? "" : "continueButtonDisabled"}`}
              onClick={() => expiresIn && handleNext()}
            >
              Verify
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Index = () => {
    return (
      <div className="popupBoxWrapper">
        <div>
          <div className="popupBox">
            <AiOutlineClose
              className="popupCloseIcon"
              onClick={() => {
                history.push("/");
                setTimeout(() => {
                  toast.warn("Your phone number is not verified");
                }, 1000);
              }}
            />
            <div className="popupTitle">Phone Verification</div>
            <div className="popupDescription">
              We will send a six digit code on this number to verify your account.
            </div>
            <MuiPhoneNumber
              disabled={!changeNumber}
              value={mynumber}
              // defaultCountry={"pk"}
              className="popupPhoneNumberInput"
              onChange={(value) => setnumber(value ? value.replace(/ |\-/g, "") : value)}
            />
            <div id="recaptcha-container"></div>
            <a className="resendCodeBtn" onClick={() => setChangeNumber(!changeNumber)}>
              {changeNumber ? "Save Number" : "Change Number"}
            </a>

            <div className="continueButton" onClick={handleNext}>
              Continue
            </div>
          </div>
        </div>
      </div>
    );
  };
  switch (currentSlid) {
    case 1:
      return VerifyPhoneNumberOTP();
    case 2:
      return VerifyPhoneNumberSuccess();
    default:
      return Index();
  }
}

const mapStateToProps = (state) => {
  return {...state.userdetail};
};

export default connect(mapStateToProps, null)(VerifyPhoneNumber);
