import React from 'react';

function TermsConditions(props) {
    return (
        <div className="traveleListWrapper" >
            <div className="traveleListBody">
           <h1>Terms and Conditions</h1> 
           <p>The purpose of this project is market research, we are not earning or chanrging any money to sender or traveler. 
            Please confirm the identity of traveler and also check the libility insurance incase your package is misplaced by traveler.
            This project is designed to measure the impact on enviroment and save dedicated shiping services since the traveler is going to travel anyways. </p>
            <p>
                We are not responsible of any data or information you share with traveler/sender through chat or any other way. We also do not share your data with any third party.
            </p>
            <p>
                By browsing our website, you are conforming that you accept the above terms and Conditions, and you understand that this project is curently non profit and only for research purpose. 
            </p>
           </div>
        </div>
    );
}

export default (TermsConditions);