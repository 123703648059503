import React, { useMemo, useState } from "react";
import { Modal, FormFields, AppBtn } from "../index";
import { UpdateUser } from "../../../services/userServices";
import { useFormik } from "formik";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import { setUserDetails } from "../../../redux/actions/userActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import MuiPhoneNumber from "material-ui-phone-number";
import FormDropdown from "../FormFields/FormDropdown";

function PersonalInfoModal(props) {
  const [isEdit, setIsEdit] = useState(true);
  const profiledata = useMemo( () => props.user, [props.user]);
  const [serverValidationErrors, setServerValidationErrors] = useState({});

  const handleEdit = () => {
    setIsEdit(false);
  };

  const { handleSubmit, handleChange,setFieldValue, values, touched, errors, handleBlur } = useFormik({
    initialValues: {
      name: profiledata?.name,
      firstname: profiledata?.firstname,
      sex: profiledata?.sex,
      username: profiledata?.username,
      phonenumber: profiledata?.phonenumber,
      useremail: profiledata?.useremail,
    },
    validationSchema: yup.object({
      firstname: yup.string().required("Should not be empty."),
      name: yup.string().required("Should not be empty."),
      username: yup.string().required("Should not be empty."),
      sex: yup.string().required("Should not be empty."),
      useremail: yup.string().required("Should not be empty."),
      phonenumber: yup.string().matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
    }),
    onSubmit: async (values) => {
      if(Object.values(serverValidationErrors).find(itm => itm))
        return;
      
      const result = await updateUser(values);
      if (result && !result?.error) {
        props.setUserDetails(result);
        props.handleClose();
      } else {
        if (result?.error) {
          const { email, phonenumber } = result.error;
          setServerValidationErrors(result.error)
          email &&
            toast.error(
              "Email address is already registered, please try different email address"
            );
          phonenumber &&
            toast.error(
              "Phone number is already registered, please try different phone number"
            );
        } else toast.error("An error occured!");
      }
    },
  });

  async function updateUser(data) {
    const result = await UpdateUser(data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => null);
    return result;
  }
console.log({errors})
  return (
    <div className="personalInfoWrapper">
      <Modal closeModal={props.handleClose} title="Personal data" buttontext="Save" submitModal={handleSubmit}>
        <form>
          <ul>
            <li>
              First name
              <i className={`${!isEdit ? "editableField" : "normalField"}`}>
                <FormFields
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={(e) => {
                    handleChange(e);
                    handleEdit();
                  }}
                  onBlur={handleBlur}
                  placeholder="First name"
                  defaultValue={values.firstname}
                />
                <span className="editSaveBtn">
                  {isEdit ? (
                    <AppBtn key={"edit"} text="Edit" onClick={handleEdit} />
                  ) : (
                    <AppBtn key={"save"} text="Save" onClick={handleSubmit} />
                  )}
                </span>
              </i>
              {touched.firstname && errors.firstname ? (
                <span className="errorMessage">{errors.firstname}</span>
              ) : null}
            </li>
            <li>
              Surname
              <i className={`${!isEdit ? "editableField" : "normalField"}`}>
                <FormFields
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => {
                    handleChange(e);
                    handleEdit();
                  }}
                  onBlur={handleBlur}
                  placeholder="Surname"
                  defaultValue={values.name}
                />
                <span className="editSaveBtn">
                  {isEdit ? (
                    <AppBtn key={"edit"} text="Edit" onClick={handleEdit} />
                  ) : (
                    <AppBtn key={"save"} text="Save" onClick={handleSubmit} />
                  )}
                </span>
              </i>
              {touched.name && errors.name ? (
                <span className="errorMessage">{errors.name}</span>
              ) : null}
            </li>
            <li>
              Gender
              <i className={`${!isEdit ? "editableField" : "normalField"}`}>
                <FormDropdown
                  value={values.sex} 
                  name="sex" 
                  onChange={(e) => {
                    handleChange(e);
                    handleEdit();
                  }}
                  onBlur={handleBlur}
                  options={[{value:"", text: "Select Gender"},
                  {value:"female", text: "Female"},
                  {value:"male", text: "Male"},
                  {value:"other", text: "Other"},
                  {value:"dontWantToSpecify", text: "Don't want to specify"},]}
                />
                <span className="editSaveBtn">
                  {isEdit ? (
                    <AppBtn key={"edit"} text="Edit" onClick={handleEdit} />
                  ) : (
                    <AppBtn key={"save"} text="Save" onClick={handleSubmit} />
                  )}
                </span>
              </i>
              {touched.sex && errors.sex ? (
                <span className="errorMessage">{errors.sex}</span>
              ) : null}
            </li>
            <li>
              E-mail address
              <i className={`${!isEdit ? "editableField" : "normalField"}`}>
                <FormFields
                  type="text"
                  name="useremail"
                  id="useremail"
                  onChange={(e) => {
                    handleChange(e);
                    setServerValidationErrors({...serverValidationErrors, email:null})
                    handleEdit();
                  }}
                  placeholder="Email"
                  defaultValue={values.useremail}
                />
                <span className="editSaveBtn">
                  {isEdit ? (
                    <AppBtn key={"edit"} text="Edit" onClick={handleEdit} />
                  ) : (
                    <AppBtn key={"save"} text="Save" onClick={handleSubmit} />
                  )}
                </span>
              </i>
              {(touched.useremail && errors.useremail || serverValidationErrors.email) ? (
                <span className="errorMessage">{touched.useremail && errors.useremail || serverValidationErrors.email}</span>
              ) : null}
            </li>
            <li>
              Mobile number
              <i className={`${!isEdit ? "editableField" : "normalField"}`}> 
                <MuiPhoneNumber 
                    defaultCountry={"pk"}
                    name="phonenumber" 
                    // className="popupPhoneNumberInput"
                    value={values.phonenumber}
                    style={{display: "flex"}}
                    onChange={(value)=>{
                      setFieldValue("phonenumber", value ? value.replace(/ |\-/g, "") : value)
                      setServerValidationErrors({...serverValidationErrors, phonenumber:null})
                      handleEdit();
                  }}
                  />
                <span className="editSaveBtn">
                  {isEdit ? (
                    <AppBtn key={"edit"} text="Edit" onClick={handleEdit} />
                  ) : (
                    <AppBtn key={"save"} text="Save" onClick={handleSubmit} />
                  )}
                </span>
              </i>
              {(touched.phonenumber && errors.phonenumber || serverValidationErrors.phonenumber) ? (
                <span className="errorMessage">{touched.phonenumber && errors.phonenumber || serverValidationErrors.phonenumber}</span>
              ) : null}
            </li>
          </ul>
        </form>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {...state.userdetail};
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoModal);
