import React from 'react';
import AboutCards from './AboutCards';
import {FaBullseye, FaEye} from 'react-icons/fa'
import {smallImage2 } from '../../resources/images/index';

function AboutUs(props) {
    return (
        <div className="aboutusWrapper">
           <h1>About us</h1> 
           <div className="aboutCardInner"> 
           <AboutCards 
                title="Mission" 
                icon={<FaBullseye />} 
                text="Our mission is to connect travelers worldwide with individuals seeking fast and affordable shipping solutions."
               /* text=" To connect travelers with people around the universe to ship the products fastest and cheapes" */
                style={{backgroundImage: `url(${smallImage2})`}}
           />

            <AboutCards 
                title="Vision" 
                icon={<FaEye />} 
                text="Our vision is to empower every traveler in the world to contribute to the improvement of our planet's environment."
                /* text=" been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries." */
                style={{backgroundImage: `url(${smallImage2})`}}
            />
           </div>
           

            
        </div>
    );
}

export default AboutUs;