import axios from "axios";
import { Redux_Global_State } from "..";

export const postRequest = (url, data = {}, params = {}) => {
  axios.defaults.headers.common["user-id"] = Redux_Global_State?.userdetail?.user?._id;
  return axios.post(url, data, { params });
};

export const putRequest = (url, data = {}, params = {}) => {
  axios.defaults.headers.common["user-id"] = Redux_Global_State?.userdetail?.user?._id;
  return axios.put(url, data, { params });
};

export const getRequest = (url, params = {}) => {
  axios.defaults.headers.common["user-id"] = Redux_Global_State?.userdetail?.user?._id;
  return axios.get(url, { params });
};

export const request = ({ method, url, data, params }) => {
  switch (method) {
    case "put":
      return putRequest(url, data, params);
    case "post":
      return postRequest(url, data, params);
    case "get":
      return getRequest(url, params);
    default:
      return null;
  }
};
