import React from "react";

const FormDropdown = (props) => {
  const {
    value,
    children,
    name,
    onChange,
    onFocus,
    onBlur, 
    options
  } = props;

  return (
    <div className="formFieldsWrapper">
      <select 
        name={name}
        onChange={onChange} 
        onFocus={onFocus}
        onBlur={onBlur}   
      >
        {options.map(itm => <option value={itm.value} selected={value == itm.value}>{itm.text}</option>)}
      </select>
      <span className="errorMessage">{children}</span>
    </div>
  );
};

export default FormDropdown;
