import React from "react";
import Avatar from "../../Components/Common/avatar";

const TravelsCard = ({
  id,
  img,
  name,
  deliverspot,
  weight,
  date,
  amount,
  requesttext,
  placeofpickup,
  submitrequest,
}) => {
  return (
    <div className="deliveryCardWrapper">
      <div className="cardLeft">
        <span className="userImage">
            <Avatar
              customStyle={{
                fontSize: "34px",
              }}
              imageLink={
                img ? `/${img}` : null
              }
              shortName={name?.split(" ")[0][0] + name?.split(" ")[1][0]}
            />
        </span>
        <span className="deliveryName">{name}</span>
        <span className="deliveryEarned">
          Earned: <i>{amount} €</i>
        </span>
      </div>
      <div className="cardmiddle">
        <span className="deliveryDate">Date: {date}</span>
        <span className="travelerLine"></span>
        <span className="travelerDestination">
          <i style={{ textAlign: "left" }}>{placeofpickup}</i>
          <i style={{ textAlign: "right" }}>{deliverspot}</i>
        </span>
      </div>
      <div className="cardRight">
        <span className="travelerBtnWrapper">
          <button onClick={() => submitrequest(id)}>Details</button>
        </span>
      </div>
    </div>
  );
};

export default TravelsCard;
