import React, { useState, useEffect } from "react";
import { AppBtn, FormFields, Header, Textarea } from "../../Components/Common/index";
import { Link, useParams, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import { connect } from "react-redux";
import { acceptPackage, declinePackage } from "../../services/packageServices";
import { GetNotification } from "../../services/notificationServices";
import { toast } from "react-toastify";
import { phone, email, endLocation, startLocation, avatar } from "../../resources/images";
import { defaultDateFormat } from "../../utils/helpers";
import Avatar from "../../Components/Common/avatar";

const Notification = (props) => {
  const [notificationDetail, setNotificationDetail] = useState(null);
  const [actionBtnActive, setActionBtnActive] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    // toast.dismiss();
  }, []);

  useEffect(() => {
    loadNotificationDetails();
  }, [id]);

  const loadNotificationDetails = async () => {
    const result = await GetNotification({ id });
    if (result && result.data) {
      setNotificationDetail(result.data);
      setActionBtnActive(result.data.package?.approvalStatus == "Pending");
    }
  };

  const handleAcceptBtn = async () => {
    await acceptPackage({ id: notificationDetail.packageId })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Package Accepted!");
          setActionBtnActive(false);
        } else toast.error("Error occured!");
      })
      .catch((err) => toast.error("Error occured!"));
  };

  const handleDeclineBtn = async () => {
    await declinePackage({ id: notificationDetail.packageId })
      .then((res) => {
        if (res.status == 200) {
          toast.success("Package Declined!");
          setActionBtnActive(false);
        } else toast.error("Error occured!");
      })
      .catch((err) => toast.error("Error occured!"));
  };
  return (
    <div className="traveleListWrapper">
      {notificationDetail?.travel && (
        <div className="traveleListBody notificationDetailsWraper">
          <h2 className="heading">{notificationDetail.message}</h2>
          {/* user Details and Request message */}
          <div className="horizFlexDiv">
            {/* left div*/}
            <div className="notificaitonImgDiv">
              <span className="userImage" style={{marginTop: "-5px"}}>
                  <Avatar
                    customStyle={{
                      fontSize: "34px",
                    }}
                    imageLink={
                      notificationDetail?.fromUser?.profilePicture
                        ? `/${notificationDetail?.fromUser?.profilePicture}`
                        : null
                    }
                    shortName={notificationDetail?.fromUser?.name?.[0] + notificationDetail?.fromUser?.firstname?.[0]}
                  />
              </span>
            </div>
            {/* right div*/}
            <div className="requestDetailsWraper">
              <h3 className="requesterName">{notificationDetail.fromUser?.name}</h3>
              <span className="requesterDateTime">
                {defaultDateFormat(notificationDetail.createdAt)}
              </span>
              {/* <div style={{ marginTop: "8px" }}>
                <img src={email} /> {notificationDetail.fromUser?.useremail}
              </div>
              <div>
                <img src={phone} /> {notificationDetail.fromUser?.phonenumber}
              </div> */}
              <div style={{ marginTop: "8px" }}>{notificationDetail.package?.message}</div>
              {actionBtnActive && (
                <div className="requestDetailsActionWraper">
                  <div className="requestDetailsAcceptBtn" onClick={handleAcceptBtn}>
                    Accept
                  </div>
                  <div className="requestDetailsRejectBtn" onClick={handleDeclineBtn}>
                    Decline
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Travel Details */}
          <div className="horizFlexDiv">
            {/* left div*/}
            <div className="notificaitonImgDiv"></div>
            {/* right div*/}
            <div className="travelDetails">
              <div className="horizFlexDiv">
                <div
                  style={{
                    maxWidth: "40%",
                    minWidth: "40%",
                  }}
                  className="travelKeyField"
                >
                  <span>Date</span>
                  <p>{defaultDateFormat(notificationDetail.travel?.dateOfPickup)}</p>
                </div>
                <div style={{ maxWidth: "30%", minWidth: "30%" }} className="travelKeyField">
                  <span>Weight</span>
                  <p>{notificationDetail.travel?.weight} kg</p>
                </div>
                <div style={{ maxWidth: "30%", minWidth: "30%" }} className="travelKeyField">
                  <span>Charges</span>
                  <p>{notificationDetail.travel?.charges} €</p>
                </div>
              </div>
              <div className="travelRoutInfoWraper">
                <div className="pick_n_dropLocInfoWraper">
                  <div style={{ maxWidth: "40%" }} className="travelKeyField">
                    <span>Pickup point</span>
                    <p>{notificationDetail.travel?.from}</p>
                  </div>
                  <div style={{ maxWidth: "40%" }} className="travelKeyField">
                    <span>Dropoff point</span>
                    <p>{notificationDetail.travel?.placeOfDropoff}</p>
                  </div>
                </div>
                <div className="pickDropLocVisual">
                  <img style={{ height: "23px", marginTop: "-9px" }} src={startLocation} />
                  <img src={endLocation} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStatetoProps = (userdetail) => {
  const { userDetail } = userdetail;
  return { userDetail };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Notification);
