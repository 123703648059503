import React, { useState, useEffect } from "react";
import { AppBtn, FormFields, Header, Textarea } from "../../Components/Common/index";
import { Link, useParams, useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import { connect } from "react-redux";
import { postPackage } from "../../services/packageServices";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

const Request = (props) => {
  const history = useHistory();
  let { id } = useParams();
  useEffect(() => {
    // toast.dismiss();
  }, []);

  const { handleSubmit, handleChange, values, touched, errors, handleBlur } = useFormik({
    initialValues: {
      travelId: id,
      message: "",
    },
    validationSchema: yup.object({
      message: yup.string().required("Message can not be empty."),
    }),
    onSubmit: async (values) => {
      console.log(values);
      const result = await sendRequest(values);
    },
  });

  async function sendRequest(data) {
    const result = await postPackage(data)
      .then((response) => {
        toast.success("Request sent successfully!");
        history.push("/my-parcels");
        return response.data;
      })
      .catch((err) => {
        toast.error("An error occured!");
        return null;
      });
    return result;
  }

  return (
    <div className="traveleListWrapper">
      <div className="traveleListBody">
        <h1>
          <i>
            <Link to="/traveller-list">
              <FaChevronLeft />
            </Link>
          </i>
          Back
        </h1>
        <form onSubmit={handleSubmit}>
          <FormFields
            type="textarea"
            id="message"
            name="message"
            placeholder="Type your request"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.message && errors.message ? (
            <span className="errorMessage">{errors.message}</span>
          ) : null}
          <AppBtn text="Send Request" onSubmit={handleSubmit} />
        </form>
      </div>
    </div>
  );
};

const mapStatetoProps = (userdetail) => {
  const { userDetail } = userdetail;
  return { userDetail };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Request);
