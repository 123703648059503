import React, { useEffect, useState,useMemo } from "react";
import "./chat.css";
import { arrowIcon } from "../../resources/images/index";
import { getChats } from "../../services/chatServices";
import { useHistory } from "react-router";
import Avatar from "../../Components/Common/avatar";
import { getFormatedChatDateTime } from "../../utils/helpers";
import { connect } from "react-redux";

 function Inbox(props) {
  const history = useHistory();
  const [chats, setChats] = useState([]); 
  const userId = useMemo( () => props.user?._id, [props.user]);

  useEffect(() => {
    console.log("inside useEffect");
    getChats()
      .then((res) => {
        setChats(res.data || []);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="inboxParentWrapper">
      <div className="inboxWrapper">
        <span className="inboxTitle">Inbox</span>
        <div className="chatListCard">
          {chats?.map((item) => {
            const thisUser = item.users.find((user) => user._id !== userId);
            return (
              <div className="chatCard" onClick={() => history.push(`/chat/${item._id}`)}>
                <div className="alignedFlexRow">
                  <div
                    style={{
                      height: "70px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      marginRight: "10px",
                      width: "70px",
                      display: "inline-block",
                    }}
                  >
                    <Avatar
                      customStyle={{
                        fontSize: "34px",
                      }}
                      imageLink={thisUser?.profilePicture ? `/${thisUser?.profilePicture}` : null}
                      shortName={thisUser?.name?.[0] + thisUser?.firstname?.[0]}
                    />
                  </div>
                  <div className="chatInfo">
                    <div className="userName">{thisUser?.firstname}</div>
                    <div className="travelDirection">
                      <div className="travelFrom">{item.travel.from}</div>
                      <img src={arrowIcon} className="direction" />
                      <div className="travelTo">{item.travel.to}</div>
                    </div>
                  </div>
                </div>
                <div className="alignedFlexRow">
                  <div className="chatTime">{getFormatedChatDateTime(item.updatedAt, true)}</div>
                  <div style={{ width: "6px", height: "6px" }} className="arrow right"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {...state.userdetail};
};

export default connect(mapStateToProps, null)(Inbox);