//const baseURL = "https://deliveroye-api.herokuapp.com/"
// const baseURL = "http://localhost:3000/";
// export const apiURL = "http://localhost:3000/";

const baseURL = "https://www.greenshipex.com/";
export const apiURL = "https://www.greenshipex.com/";

const config = {
  REST_API: {
    Package: {
      GetPackage: `${baseURL}packages`,
    },
    User: {
      GetUser: `${baseURL}user`,
      RegisterUser: `${baseURL}user`,
      UpdateUser: `${baseURL}user`,
    },
  },
};

export default config;
