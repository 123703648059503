export const actionTypes = {
  SET_USER_DETAILS : "SET_USER_DETAILS",
  LOGOUT_USER: "LOGOUT_USER"
}

export const setUserDetails = user => ({
  type: actionTypes.SET_USER_DETAILS,
  payload: user
})

export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER,
})

// export const usernameActions = () => dispatch =>
//   axios.get('https://deliveroye-api.herokuapp.com/api/package?id=1')
//   .then((response) => {
//       //  debugger;
//       dispatch({
//            type: USER_ACTIONS.SET_USER_DETAILS,
//            payload: {
//            usename: 'Manaqib',
//          },
//        })
//   })

export const usernameActions = () => dispatch => {}
  // axios.get('http://localhost:3000/api/packages')
  // .then((response) => {
  //     dispatch({
  //          type: USER_ACTIONS.SET_USER_DETAILS,
  //          payload: {
  //          usename: 'Manaqib',
  //        },
  //      })
  // })


