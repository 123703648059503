import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Header } from ".././../Components/Common/index";
import DeliveryList from "./DeliveryList";

function MyDeliveries(props) {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  return (
    <div className="mydeliveriesWrapper">
      <DeliveryList />
    </div>
  );
}

export default MyDeliveries;
