import { INITIAL_STATE as APP_INITIAL_STATE } from "./reducers/appReducer";
import { INITIAL_STATE as USER_INITIAL_STATE } from "./reducers/userReducer";

export const saveState = (state) => {
    try{
        const serializedState = JSON.stringify(state);
        localStorage.setItem("reduxPersist", serializedState);
    }catch(err){
        console.log(err)
    }
}

export const loadState = () => {
    try{
        const state = JSON.parse(localStorage.getItem("reduxPersist"))
        if(state)
        return state;
    }catch(error){
        console.log(error);
    }
    return {app: APP_INITIAL_STATE, userdetail: USER_INITIAL_STATE}
}