import { actionTypes } from "../actions/appActions"

export const INITIAL_STATE = {
    isLoggedIn: false,
    popupState: {
        loginPopup: false,
        registerPopup: false,
        forgotPasswordPopup: false,
        findTravelPopup: false
    }
}

const reducer = (state, action) => {
    if (!state) state = INITIAL_STATE;
    switch (action.type) {
        case actionTypes.SET_LOGIN_STATE:
            return {
                ...state,
                isLoggedIn: Boolean(action.payload)
            }
        case actionTypes.SET_POPUP_STATE:
            return {
                ...state,
                popupState: { ...INITIAL_STATE.popupState, ...action.payload }
            }
        default:
            return state
    }
}

export default reducer;