import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footerWrapper">
            <span>&copy; 2023 - All Rights Reserved. </span>
            <span>
                
                <ul>
                    <li><a href="/aboutus">About us</a> </li>
                    <li><a href="/terms">Terms & Conditions</a> </li>
                    <li><a href="/privacy">Privacy Policy</a> </li>
                    <li><a href="/feedback">Feedback</a> </li>
                    <li><a href="/support">Support</a> </li>
                    <li><a href="#">FAQs</a> </li>
                </ul>
                
            </span>
        </div>
    );
};

export default Footer;