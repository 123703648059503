import React from 'react';

const AppBtn = ({text, onClick}) => {
    return (
        <div className="btnWrapper small">
            <button onClick={onClick}>{text}</button>
        </div>
    );
};

export default AppBtn;