import React from 'react';
import {Textarea, AppBtn} from '../../Components/Common/index';

function Feedback(props) {
    return (
           <div className="traveleListWrapper">
            <div className="traveleListBody">
           <h1>Feedback</h1> 
           
           <form>

           <div className="fieldWrapper textareaWrapper">
               <Textarea placeholder="Write your feedbach here"></Textarea>              
           </div>
           
               <AppBtn text="Submit" />            
           
           </form>
           </div>
       </div>

    );
}

export default Feedback;