import React from 'react';

function AboutCards({title, heading, text, style, icon}) {
    return (
        <div className="aboutCardWrapper" style={style}>           
            <span className="aboutCardHeading"> 
            
             <span className="aboutCardTitle"><span className="cardIcon">{icon}</span> {title}</span>
            </span>
            <span className="aboutCardsBody">
               <p> {text}</p>
            </span>          
        </div>
    );
}

export default AboutCards;