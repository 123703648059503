import axios from "axios";
import React, { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { FaCheckCircle, FaRegCheckCircle, FaCamera } from "react-icons/fa";
import { UsersAPIs } from "../../api/apiEndPoints";
import Avatar from "../../Components/Common/avatar";

function Profile({
  name,
  subheading,
  picture,
  editprofile,
  editprefrences,
  phone,
  email,
  editPersonalInfo,
  isEmailVerified,
  isContactNoVerified,
  triggerGetFreshData,
}) {
  const uploadProfilePicure = (e) => {
    let formData = new FormData();
    formData.append("avatar", e.target.files[0]);
    axios
      .post(UsersAPIs.UPLOAD_PROFILE_PICTURE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status == 200) triggerGetFreshData();
      });
  };

  return (
    <div className="profileWrapper">
      <span className="profileInner">
        <span className="profileHeader">
          <span className="userName">
            {name} <i>{subheading}</i>
          </span>
          <span className="userPicture">
            <Avatar imageLink={picture} shortName={name?.[0] + subheading?.[0]} />
            <div class="upload-btn-wrapper">
              <label for="avatar">
                <FaCamera className="btn" />
              </label>
              <input type="file" id="avatar" onChange={uploadProfilePicure} name="avatar" />
            </div>
          </span>
        </span>

        <span className="profileBody">
          <ul>
            <li onClick={editPersonalInfo}>
              <BsPlusCircle /> {editprofile}
            </li>
            <li>
              <BsPlusCircle /> {editprefrences}
            </li>
          </ul>
        </span>

        <span className="profileFooter">
          <ul>
            <li className={isContactNoVerified ? "" : "notVerified"} style={{ cursor: "default" }}>
              <FaCheckCircle />
              {phone}
              {!isContactNoVerified ? (
                <a href="/verifyPhoneNumber" style={{ marginLeft: "15px" }}>
                  Verify
                </a>
              ) : null}
            </li>
            <li className={isEmailVerified ? "" : "notVerified"} style={{ cursor: "default" }}>
              <FaCheckCircle />
              {email}
            </li>
          </ul>
        </span>
      </span>
    </div>
  );
}

export default Profile;
