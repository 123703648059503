import React, { useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Modal, FormFields } from "../index";
import { useFormik } from "formik";
import * as yup from "yup";
import { getUser } from "../../../services/userServices";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { setUserDetails } from "../../../redux/actions/userActions";
import { setLoginState, setPopupState } from "../../../redux/actions/appActions";
import { connect } from "react-redux";

function ModalLogin(props) {
  const history = useHistory();
  const location = useLocation();


  const { handleSubmit, handleChange, values, touched, errors, setFieldValue, handleBlur } =
    useFormik({
      initialValues: {
        useremail: "",
        password: "",
      },
      validationSchema: yup.object({
        useremail: yup.string().email().required("Email can not be empty."),
        password: yup.string().required("Password can not be empty."),
      }),
      onSubmit: async (values) => {
        await getUser(values)
          .then((response) => {
            if (response.data && !response.data?.error) {
              props.setUserDetails(response.data);
              props.setLoginState(true);
              props.setPopupState({ loginPopup: false })
              !response.data.isEmailVerified && toast.info("Your email is not verified!");
              response.data.isContactNoVerified
                ? !location.pathname.includes("traveller-list") && history.push("/")
                : history.push("/verifyPhoneNumber");
            } else {
              toast.error("Password or Email is not valid");
              setFieldValue("password", "");
            }
          })
          .catch((err) => {
            toast.error("Password or Email is not valid");
            setFieldValue("password", "");
          });
      },
    });

  return (
    <div>
      <Modal
        closeModal={() => { props.setPopupState({ loginPopup: false }); location.state?.redirectTo && history.push(location.state?.redirectTo) }}
        submitModal={handleSubmit}
        buttontext="Login"
        title="Login"
        handleRegisterNow={() => props.setPopupState({ registerPopup: true })}
      >
        <form onSubmit={handleSubmit}>
          <FormFields
            type="text"
            id="useremail"
            name="useremail"
            placeholder="Email"
            value={values.useremail}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.useremail && errors.useremail ? (
            <span className="errorMessage">{errors.useremail}</span>
          ) : null}
          <FormFields
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {touched.password && errors.password ? (
            <span className="errorMessage">{errors.password}</span>
          ) : null}
          <input type="submit" style={{ display: "none" }} />
          <Link to="/forgot_password" className="forgetPassword">Forgot Password?</Link>

        </form>

      </Modal>
    </div>
  );
}


const mapStateToProps = (state) => {
  return { ...state.userdetail };
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(setUserDetails, dispatch),
  setPopupState: bindActionCreators(setPopupState, dispatch),
  setLoginState: bindActionCreators(setLoginState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);
