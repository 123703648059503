import React, { useState } from "react";
import ModelChangePassword from "../../Components/Common/Modal/ModalChangePassword";

function AccountInfo({
  ratingrec,
  ratinggiven,
  notification,
  changepassword,
  payouts,
  paymentmathonds,
  privacy,
}) {
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div className="accountInfoWrapper">
      <ul>
        <li>{ratingrec}</li>
        <li>{ratinggiven}</li>
        <li>{notification}</li>
        <li onClick={() => setShowLogin(true)}>{changepassword}</li>
        <li>{payouts}</li>
        <li>{paymentmathonds}</li>
        <li>{privacy}</li>
      </ul>
      {showLogin && <ModelChangePassword handleCLose={() => setShowLogin(false)} />}
    </div>
  );
}

export default AccountInfo;
