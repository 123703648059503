import React, { useEffect } from "react";
import { FormFields, AppBtn } from ".././../Components/Common/index";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  useEffect(() => {
    // toast.dismiss();
  }, []);

  return (
    <div className="loginWrapper">
      <div className="loginInner">
        <div className="container">
          <h1>Sign-In</h1>

          <form>
            <FormFields type="text" placeholder="Enter username" />
            <FormFields type="password" placeholder="Enter password" />
            <AppBtn text="Sign in" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
