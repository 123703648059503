import React, { useEffect, useState } from "react";
import TravelerCard from "./TravelerCard";
import { useHistory, useLocation } from "react-router-dom";
import { getTravellers } from "../../services/travelServices";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { defaultDateFormat } from "../../utils/helpers";
import { setLoginState, setPopupState } from "../../redux/actions/appActions";

const TravellerList = (props) => {
  const isLoggedIn = Boolean(props.userdetail?.user?.useremail)

  const [travelers, setTravelers] = useState({});
  const history = useHistory();
  const location = useLocation();
  const request = (itemid) => history.push(`/request/${itemid}`);

  useEffect(() => {
    if (location.state) travelslist(location.state.filter);
  }, []);

  const travelslist = (params) =>
    getTravellers(params).then((response) => {
      setTravelers(response.data);
    });
  return (
    <div className="traveleListWrapper">
      <div className="traveleListBody"> 
        {travelers?.isReleventData && (
          <div className="custom_hr">
            <div>Travellers not found. Search again with different criteria.</div>
          </div>
        )}
        {travelers?.data?.map &&
          travelers.data.map((item, index) => {
            return (
              <TravelerCard
                key={index}
                id={item._id}
                img={item.travelerDetails.profilePicture}
                name={`${item.travelerDetails?.name} ${item.travelerDetails?.firstname}`}
                dateOfPickup={`${defaultDateFormat(item.dateOfPickup)}`}
                requesttext="Request"
                weight={item.weight}
                amount={item.charges}
                to={item.to}
                from={item.from}
                placeOfDropoff={item.placeOfDropoff}
                placeOfPickup={item.placeOfPickup}
                dateOfDelivery={item.dateOfDelivery}
                submitrequest={isLoggedIn ? request : () => props.setPopupState({loginPopup:true})}
              />
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserDetails: bindActionCreators(userActions.setUserDetails, dispatch), 
  setPopupState: bindActionCreators(setPopupState, dispatch),
  setLoginState: bindActionCreators(setLoginState, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TravellerList);
