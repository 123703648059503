import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
// import Avatar from "../../Components/Common/avatar";
import { defaultDateFormat } from "../../utils/helpers";

const TravelerCard = ({
  id,
  img,
  name,
  weight,
  from,
  to,
  dateOfPickup,
  dateOfDelivery,
  placeOfDropoff,
  placeOfPickup,
  amount,
  requesttext,
  submitrequest,
}) => {
  return (
    <div className="detailsInner travelerCardWrapper">
      <div className="cardLeft">
        {/* <span className="userImage">
            <Avatar
              customStyle={{
                fontSize: "34px",
              }}
              imageLink={
                img ? `/${img}` : null
              }
              shortName={name?.split(" ")[0][0] + name?.split(" ")[1][0]}
            />
        </span> */}
        <span className="travelerName">{name}</span>
        <span className="travelerspace">
          Available space: <i>{weight} KG</i>
        </span>
      </div>
      <div className="cardmiddle">
        <span className="leftLocation">{placeOfPickup}</span>
        <div>
          <span className="travelerDestination">
            <i>{dateOfPickup && `${defaultDateFormat(dateOfPickup)}`}</i>
            <i>{dateOfDelivery && `${defaultDateFormat(dateOfDelivery)}`}</i>
          </span>
          <span className="travelerLine"></span>
          <span className="travelerDestination">
            <i>
              <FaMapMarkerAlt />
              {from}
            </i>
            <i>
              <FaMapMarkerAlt />
              {to}
            </i>
          </span>
        </div>
        <span className="rightLocation">{placeOfDropoff}</span>
      </div>

      <div className="cardRight">
        <span className="travelerAmount">{amount} €</span>
        <span className="travelerBtnWrapper">
          <button onClick={() => submitrequest(id)}>{requesttext}</button>
        </span>
      </div>
    </div>
  );
};

export default TravelerCard;
