import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Header } from "../../Components/Common/index";
import TravelsList from "./TravelsList";

function MyTravels(props) {
  useEffect(() => {
    // toast.dismiss();
  }, []);
  return (
    <div className="mydeliveriesWrapper">
      <TravelsList />
    </div>
  );
}

export default MyTravels;
