import { MyNotificationAPIs } from "../api/apiEndPoints";
import { request } from "../api/axiosDefault";

export const GetMyNotifications = () =>
  request({ method: "get", url: MyNotificationAPIs.GET_MY_NOTIFICATION });

export const GetNotification = (params) =>
  request({ method: "get", url: MyNotificationAPIs.GET_NOTIFICATION, params });

export const MarkAsRead = () => request({ method: "put", url: MyNotificationAPIs.MARK_AS_READ });
