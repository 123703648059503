import React, { useEffect, useState,useMemo } from "react";
import { VerifyEmail as verifyEmailAPI } from "../../services/userServices";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

const VerifyEmail = (props) => {
  const history = useHistory();
  let { token } = useParams();
  const [verified, setVerfied] = useState(false);
  const [isVerfying, setIsVerfying] = useState(true);
  const user = useMemo( () => props.user, [props.user]);

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    const result = await verifyEmailAPI(token)
      .then((response) => {
        setVerfied(true);
        return response.data;
      })
      .catch((err) => null);
    setIsVerfying(false);
    if (result)
      user.isContactNoVerified === false
        ? history.push("/verifyPhoneNumber")
        : history.push("/home");
  };

  return (
    <div className="loginWrapper">
      <div className="loginInner">
        <div className="container">
          <h1>
            {!isVerfying
              ? verified
                ? "Thank you!, your email is now verified."
                : "Unable to verify with the link."
              : "Verifying..."}
          </h1>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {...state.userdetail};
};

export default connect(mapStateToProps, null)(VerifyEmail);
